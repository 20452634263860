import newsquawk from "src/style/images/newsquawk.png";

export const premiumDataDetails = [
  {
    _id: "1",
    title: "Newsquawk",
    price: "140",
    termsRequired: true, // terms and condition required for checkout page or not
    checkoutTerms: true, //checkout user term checked or not
    piqTermsRequired: false, ///// terms and condition required for appselector page for piq to email or not
    checked: false, //appselector user checked or not
    img: newsquawk,
    paid: false,
  },
];
