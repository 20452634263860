import { trackEvents } from "src/store/Tracker/config"
import { upperCase } from "lodash";
import { useContext, useEffect } from "react";
import { DeviceInfoContext } from "src/context/DeviceInfoProvider";
import mixpanel from 'mixpanel-browser';
import { activeDrawer } from "src/interface/PiqState";
import { isLocalhost } from "src/serviceWorker";


mixpanel.init("7b9b7afcaae63e4312f16c8f1f2d1f62", {
    // debug: true, 
    track_pageview: true,
    persistence: 'localStorage'
});




const useTracker = () => {

    const deviceInfo = useContext(DeviceInfoContext)

    // const { isSignedIn: isUserLoggedIn } = useAuthContext();

    useEffect(() => {
        if (deviceInfo?.deviceId && deviceInfo?.deviceId !== '' && !isLocalhost) {
            mixpanel.identify(deviceInfo?.deviceId)
        }
    }, [deviceInfo?.deviceId])

    const sendDeviceInfo = async () => {


    }

    const trackBtnClick = async ({
        type
    }: {
        type: trackEvents
    }) => {
        // if (isLocalhost) return;
        mixpanel.track(type)

    }


    const trackFieldChange = ({
        type,
        fieldName
    }: {
        type: trackEvents,
        fieldName: string;
    }) => {

        mixpanel.track(`S_U_${upperCase(fieldName)?.replace(/ /g, "_")}`)

    }

    const trackComponentMount = async ({
        type
    }: {
        type: trackEvents
    }) => {

    }

    const sendActiveSessionDuration = async (originalTime: number) => {
        // if (isLocalhost) return;

        const currentTime = new Date().getTime();
        // const activeSessionTime = tracker?.data?.activeSessionTime ?? 0; // Default to 0 if undefined

        const difference = currentTime - originalTime;


        const seconds = Math.floor((difference / 1000) % 60);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const days = Math.floor(difference / (1000 * 60 * 60 * 24));

        const readableDifference = `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;


        mixpanel.track("SESSION_END", {
            duration: readableDifference
        })
    }

    const trackSignUpSuccess = async (data: any) => {

        mixpanel.track("SIGNUP_SUCCESS", {
            first_name: data?.first_name,
            last_name: data?.last_name,
            email: data?.email,
            user_id: data?.user_id
        })
        mixpanel.people.set({
            $first_name: data?.first_name,
            $last_name: data?.last_name,
            $email: data?.email,
            $user_id: data?.user_id,
            $created: new Date().toISOString(),
        });

    }

    const handleDrawerClose = (activeDrawer: activeDrawer) => {

        if (activeDrawer === 'signin-drawer') {
            mixpanel.track(trackEvents.SIGN_IN_DRAWER_CLOSED)
        } else if (activeDrawer === 'signup-drawer') {
            mixpanel.track(trackEvents.REGISTER_DRAWER_CLOSED)
        } else if (activeDrawer === 'forgot-password-drawer') {
            mixpanel.track(trackEvents.RESET_PASSWORD_DRAWER_CLOSED)
        }
    }

    const handleDrawerOpen = (activeDrawer: activeDrawer) => {

        if (activeDrawer === 'signin-drawer') {
            mixpanel.track(trackEvents.SIGN_IN_DRAWER_OPEN)
        } else if (activeDrawer === 'signup-drawer') {
            mixpanel.track(trackEvents.REGISTER_DRAWER_OPEN)
        } else if (activeDrawer === 'forgot-password-drawer') {
            mixpanel.track(trackEvents.RESET_PASSWORD_DRAWER_OPEN)
        }
    }


    return {
        trackBtnClick,
        trackFieldChange,
        trackComponentMount,
        sendDeviceInfo,
        sendActiveSessionDuration,
        trackSignUpSuccess,
        handleDrawerClose,
        handleDrawerOpen
    }

}

export default useTracker;