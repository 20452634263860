import { Auth, Hub } from "aws-amplify";
import { useEffect, useState } from "react";
import {
  axiosInstance,
  remove_auth_headers,
  updateAxiosInstance,
} from "src/store/utility";
import jwt from "jsonwebtoken";
import { useDispatch } from "react-redux";
import { logoutReset, setUserDetails } from "src/store/User/action";
import { updateLoading } from "src/store/Loading";
import { loginSocketEmit } from "src/Socket";
import plausible from "src/plausible";
import { fetchSubscriptionData } from "src/store/Subscriptions/subscriptionSlice";
import { popMessage } from "src/development/Component/message/message";
import { limitExceedTracker } from "src/utils/limitExceed";
import { update_user_details } from "src/store/userStore";

export const useAuth = () => {
  const [user, setUser] = useState(null);
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [token, setToken] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    checkUser();
    const interval = setInterval(() => {
      checkUser();
    }, 36e5);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Hub.listen("auth", async (data) => {
      const { payload } = data;
      if (payload.event === "signIn") {
        // await axios.post(`${config.PROD_URL}auth/saveUser`, {
        //   headers: {
        //     "x-access-token":
        //       payload?.data?.signInUserSession?.accessToken?.jwtToken,
        //   },
        // });
        // console.log(data);
        // localStorage.setItem("temp", JSON.stringify(data));
        checkUser();
        setIsSignedIn(true);
      }
      if (payload.event === "cognitoHostedUI") {
        checkGmailSignIn();
        checkUser();
        setIsSignedIn(true);
      }
      if (payload.event === "signOut") {
        setUser(null);
        setIsSignedIn(false);
      }

      if (payload.event === "configured") {
        checkUser();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await getToken();
      setUser(user);
      // localStorage.removeItem("q-limit-z");
      setIsSignedIn(true);
    } catch (err) {
      limitExceedTracker();
      setIsSignedIn(false);
      updateAxiosInstance("", "", "");
    }
  };

  const checkGmailSignIn = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();

      dispatch(updateLoading({ key: "dashboardloading", value: true }) as any);
      if (!Auth || typeof Auth.signIn !== "function") {
        throw new Error(
          "No Auth module found, please ensure @aws-amplify/auth is imported"
        );
      }

      // layoutProps?.setPiqSuiteLayout([]);
      const authToken = user.signInUserSession.accessToken.jwtToken;

      updateAxiosInstance(authToken, user?.username, user?.attributes?.email);
      const getUpdatesEmailFromPiQ: boolean = JSON.parse(
        window.localStorage.getItem("getUpdatesEmailFromPiQ") || "false"
      );
      loginSocketEmit(user?.attributes?.email);

      plausible.trackEvent("Login");
      dispatch(fetchSubscriptionData());
      const queryParamValue = localStorage.getItem("utm_source");
      if (queryParamValue !== null) {
        plausible.trackEvent(`${queryParamValue}Signin`, {
          utm_source: queryParamValue,
        });
        localStorage.removeItem("utm_source");
      }
      axiosInstance
        .post(
          `/auth/saveUser`,
          { getUpdatesEmailFromPiQ },
          {
            headers: {
              "x-access-token": authToken,
            },
          }
        )
        .then(
          (res: any) => {
            dispatch(setUserDetails(res.data.data));
            dispatch(update_user_details(res.data.data));
            dispatch(fetchSubscriptionData());
          },
          async (err) => {
            // toggleSigninLoading(false);
            let errRes = err?.response;
            // handleLogout();
            if (errRes?.data?.msg !== "") {
              popMessage.errorMessage(errRes?.data?.msg);
            }
            remove_auth_headers();
            // dispatch(
            //   updateLoading({ key: "dashboardloading", value: false }) as any
            // );
          }
        );
    } catch (err: any) {
      console.log(err);
      // dispatch(updateLoading({ key: "dashboardloading", value: false }) as any);
      // if (err.code === "UserNotConfirmedException") {
      //   setEmailAddress(username);
      //   Auth.resendSignUp(username);
      //   localStorage.setItem("piq-user-emailAddress", username);
      //   props?.setActiveDrawer("confirm-signup-drawer");
      // } else if (err.code === "PasswordResetRequiredException") {
      //   Auth.resendSignUp(username);
      //   props?.setActiveDrawer("forgot-password-drawer");
      // } else {
      //   popMessage.errorMessage(err.message);
      // }
    } finally {
      // toggleSigninLoading(false);
      dispatch(updateLoading({ key: "dashboardloading", value: false }) as any);
    }
  };

  const getToken = async () => {
    try {
      const currentSession: any = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken().getJwtToken();
      setToken(accessToken);

      const decodedPayload: any = jwt.decode(accessToken);
      const expirationTime = decodedPayload?.exp;
      const currentTime = Math.floor(Date.now() / 1000);

      if (currentTime <= expirationTime) {
      } else {
        const time = setTimeout(async () => {
          dispatch(logoutReset() as any);
        }, 5000);
        return () => clearTimeout(time);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return { user, token, isSignedIn };
};
