import React, { lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useUser } from "src/Hooks/useUser";
import { pushAdsToBody } from "src/utils/common";
import PagesRoute from "./PagesRoute";
import { LoaderComponent } from "react-fullscreen-loader";
import { GlobalProps, LayoutProps } from "src/interface/PiqState";
import HeaderNav from "../HeaderNav/HeaderNav";
import { useAuthContext } from "src/context/AuthProvider";
const SignIn = lazy(() => import("src/development/Component/Drawers/Auth"));

const LogoutModal = lazy(
  () => import("src/development/Container/Web/Components/SideBar/LogoutModal")
);
const PopupAds = lazy(() => import("src/development/Component/PopupAds"));

const AddCardDialog = lazy(
  () => import("src/development/Component/AppSelector")
);
const LimitExceedModal = lazy(
  () => import("src/development/Component/modal/LimitExceedModal")
);
const SideBar = lazy(
  () => import("src/development/Container/Web/Components/SideBar")
);
const UserProfile = lazy(
  () => import("src/development/Component/Drawers/UserProfile/UserProfile")
);
const Settings = lazy(
  () => import("src/development/Component/Drawers/Setting")
);
// const EmailMeForDesktop = lazy(
//   () =>
//     import(
//       "src/development/Component/Drawers/EmailMeForDesktop/EmailMeForDesktop"
//     )
// );
const TypeForm = lazy(
  () => import("src/development/Component/Drawers/TypeForm")
);
const Payment = lazy(
  () => import("src/development/Component/StripePayment/Payment")
);
const AddFeatures = lazy(
  () => import("src/development/Component/Drawers/AddFeatures")
);
const ReuterDrawer = lazy(
  () => import("src/development/Component/Drawers/Articles/index")
);
const PremiumAppCheckout = lazy(
  () => import("src/development/Component/AppSelector/PremiumAppCheckout")
);
const AppLayout: React.FC<{
  props: GlobalProps;
  layoutProps: LayoutProps;
}> = ({ props, layoutProps }) => {
  const history = useHistory();
  const { SHOW_ADS } = useUser();
  const [layoutIsEmpty, setLayoutIsEmpty] = React.useState<boolean>(true);
  const [emailMePopUp, setEmailMePopUp] = React.useState<boolean>(false);
  const { darkMode, windowSize, dashboardloading } = useSelector(
    (state: any) => ({
      darkMode: state?.user?.darkMode,
      windowSize: state?.PIQReducer?.windowSize,
      dashboardloading: state?.loading?.dashboardloading,
    })
  );
  useEffect(() => {
    if (layoutProps?.piqSuiteLayout?.length) {
      setLayoutIsEmpty(false);
    } else {
      setLayoutIsEmpty(true);
      const time = setTimeout(() => {
        setLayoutIsEmpty(false);
      }, 1000);
      return () => {
        clearTimeout(time);
      };
    }
  }, [layoutProps?.piqSuiteLayout?.length]);

  useEffect(() => {
    if (props?.activeDrawerState === "app-selector-drawer") {
      document.title = "PiQ | App Selector";
      history.push("/");
    } else if (props?.activeDrawerState === "article-list-drawer") {
      document.title = "PiQ | Articles";
      history.push("/");
    } else if (props?.activeDrawerState === "account-settings-drawer") {
      document.title = "PiQ | Account Settings";
      history.push("/");
    } else if (props?.activeDrawerState === "global-settings-drawer") {
      document.title = "PiQ | Global Settings";
      history.push("/");
    } else if (props?.activeDrawerState === "upgrade-plan") {
      document.title = "PiQ | Payment";
      history.push("/");
    } else if (props?.activeDrawerState === "type-form") {
      document.title = "Form";
      history.push("/");
    } else {
      document.title = "PiQSuite.Com";
    }
  }, [props?.activeDrawerState, history]);
  useEffect(() => {
    if (windowSize?.[0]) {
      pushAdsToBody(windowSize?.[0]);
    }
  }, [windowSize]);

  useEffect(() => {
    if (!SHOW_ADS) {
      const existingDev = document.getElementById("ic_d_div_OOP_1");
      if (existingDev) {
        existingDev.remove();
      }
    }
  }, [SHOW_ADS]);

  useEffect(() => {
    if (windowSize?.[0] <= 700) {
      if (!emailMePopUp) {
        setTimeout(() => {
          setEmailMePopUp(true);
        }, 5000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize]);

  return (
    <>
      {/* {emailMePopUp && <EmailMeForDesktop
        setEmailMePopUp={(bol: boolean) => setEmailMePopUp(bol)}
      />} */}
      <>
         <HeaderNav props={{ ...props }}/>
        <LoaderComponent loading={dashboardloading || layoutIsEmpty} />
        <ReuterDrawer {...props} />
        <Settings props={{ ...props }} layoutProps={{ ...layoutProps }} />
        <TypeForm props={{ ...props }} />
        <Payment props={{ ...props }} layoutProps={{ ...layoutProps }} />
        {!(windowSize?.[0] <= 700) ? (
          <>
            <PopupAds props={{ ...props }} />
          </>
        ) : (
          <></>
        )}
        <PremiumAppCheckout {...props} />
        <UserProfile props={{ ...props }} layoutProps={{ ...layoutProps }} />
        <SignIn props={{ ...props }} layoutProps={{ ...layoutProps }} />
        <LogoutModal props={{ ...props }} layoutProps={{ ...layoutProps }} />
        <AddCardDialog props={{ ...props }} layoutProps={{ ...layoutProps }} />
        <LimitExceedModal props={{ ...props }} />
        <AddFeatures {...props} />
        <div className={`v2-authenticated-page ${darkMode ? "dark-mode" : ""}`}>
          {!(windowSize?.[0] <= 700) ? (
            <>
              <SideBar {...props} />
            </>
          ) : (
            <></>
          )}
          <div
            className={`main-panel overflow-y-auto ${darkMode ? "dark-mode" : ""
              }`}
            style={{ height: "100%" }}
          >
            <PagesRoute
              // Dashboard={windowSize?.[0] <= 700 ? true : false} //mobile: true, web: false
              {...props}
              {...layoutProps}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default AppLayout;
