import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthContext } from 'src/context/AuthProvider';
import { SingleKeywordColConfig } from 'src/interface/keyword';
import { getSingleColumnKeyword } from 'src/store/PIQ/action';
import { KEYWORD_COLOR_LIST } from 'src/SuiteConfig/keywordColorList';
const useColConfig = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const context: any = useAuthContext();
    const { isSignedIn: isUserLoggedIn } = useAuthContext();

    const dispatch = useDispatch();
    const [colConfig, setColConfig] = useState<SingleKeywordColConfig>({
        displayInAlertToster: false,
        highlightEntireCard: false,
        audioAlert: false,
        singleWordPhraseKeywords: [],
        i: props?.socket,
        email: context?.user?.attributes?.email,
    });

    useEffect(() => {
        if (isUserLoggedIn && context?.user?.attributes?.email) {
            setLoading(true);
            getSingleColumnKeyword({
                email: context?.user?.attributes?.email,
                i: props?.socket,
            }).then((res) => {
                res?.data &&
                    setColConfig({
                        ...res?.data,
                        singleWordPhraseKeywords: res?.data?.singleWordPhraseKeywords?.map(
                            (item: any, index: number) => {
                                let colorIndex = 0;
                                if (index < KEYWORD_COLOR_LIST.length) {
                                    colorIndex = index;
                                } else {
                                    colorIndex = index % KEYWORD_COLOR_LIST.length;
                                }
                                const color = KEYWORD_COLOR_LIST[colorIndex]?.hexcode;
                                const fontColor = KEYWORD_COLOR_LIST[colorIndex]?.fontColor;
                                return { name: item, backgroundColor: color, fontColor: fontColor };
                            }
                        ),
                    });
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                setLoading(false);
            });

        } else {
            setColConfig({
                displayInAlertToster: false,
                highlightEntireCard: false,
                audioAlert: false,
                singleWordPhraseKeywords: [],
                i: props?.socket,
                email: '',
            });
        }
    }, [dispatch, props?.socket, props?.showEditPanel, context?.user?.attributes?.email, isUserLoggedIn]);

    return {
        colConfig,
        setColConfig,
        loading
    }
}


export default useColConfig;