import { Button, Collapse } from "antd";
import React, { Dispatch, SetStateAction, useContext } from "react";
import closeIcon from "src/style/images/close_icon.svg";
import { CloseCircleOutlined, SettingFilled } from "@ant-design/icons";
import EditPanel from "./EditPanel";
import { GlobalProps, LayoutProps, SocketNames } from "src/interface/PiqState";
import { CaretRightOutlined } from "@ant-design/icons";
import KeywordHighlight from "./KeywordHighlight";
import useColumnHeader from "src/Hooks/useColumnHeader";
import FilterPanel from "./FilterPanel";
import CustomFeedNameEdit from "./CustomFeedNameEdit";
import CustomFeedAdd from "./CustomFeedAdd";
import { customFeedsSockets } from "src/SuiteConfig/columnConfiguration";
import { IndividualFeedContext } from "src/context/IndividualFeedProvider";
const { Panel } = Collapse;
const ColumnHeaderOption: React.FC<{
  setShowEditPanel: (params: any) => any;
  socket: SocketNames;
  props: GlobalProps;
  layoutProps: LayoutProps;
  defaultActivePanel?: string;
}> = ({ setShowEditPanel, socket, props, layoutProps, defaultActivePanel }) => {
  const { removeColumn } = useColumnHeader(layoutProps);

  const { setOpenByDefault } = useContext(IndividualFeedContext);

  return (
    <div
      className="v2-column-header-option"
    // style={{
    //   height: socket === "harkster" || socket === "forexLive" || socket === "telegram" ? "96%" : socket === "reuters" ? "94%" : socket === "tradingview" ? "91%" : "100%",
    //   width: socket === "harkster" || socket === "forexLive" ? "96%" : socket === "reuters" ? "98.6%" : "99%",

    // }}
    >
      <div style={{ textAlign: "right" }}>
        <Button
          type="text"
          shape="circle"
          size="small"
          className="v2-column-header-option-close-btn"
          icon={
            <img
              src={closeIcon}
              alt=""
              className="v2-column-header-option-close-btn-icon"
            />
          }
          onClick={() => {
            setShowEditPanel(false);
            setOpenByDefault("");
          }}
        />
      </div>

      {socket === "tradingview" ||
        socket === "investing-economic-calender" ||
        socket === "telegram" ? (
        ""
      ) : (
        <>
          <div className="v2-edit-panel-setting-title-div">
            <div className="v2-edit-panel-setting-title">Feed Settings</div>
            <SettingFilled className="v2-edit-panel-setting-icon" />
          </div>

          <div className="settings-divider"></div>

          <Collapse
            accordion
            className="v2-edit-panel-collapse1"
            bordered={false}
            expandIconPosition={"end"}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
            defaultActiveKey={defaultActivePanel ?? ""}
          >
            {/* <Panel
              header="Edit Feed Name"
              key="1"
              style={{
                border: "none",
                marginBottom: "5px",
                cursor: "pointer",
              }}
            >
              <CustomFeedNameEdit socket={socket} props={props} />
            </Panel> */}
            {/* <Panel
              header="Add Feeds"
              key="2"
              style={{
                border: "none",
                marginBottom: "5px",
                cursor: "pointer",
              }}
            >
              <CustomFeedAdd socket={socket} props={props} />
            </Panel> */}
            <Panel
              header="Keyword Alerts"
              key="3"
              style={{ border: "none", marginBottom: "5px", cursor: "pointer" }}
            >
              <KeywordHighlight socket={socket} props={props} />
            </Panel>

            <Panel
              header="Change Feed"
              key="4"
              style={{ border: "none", marginBottom: "5px", cursor: "pointer" }}
            >
              <EditPanel socket={socket} layoutProps={layoutProps} />
            </Panel>
            <Panel
              header="Conditional Filter"
              key="5"
              style={{ border: "none" }}
            >
              <FilterPanel
                socket={socket}
                props={props}
              // layoutProps={layoutProps}
              />
            </Panel>
          </Collapse>

          <div className="settings-divider"></div>
        </>
      )}

      <div
        style={{ color: "red", cursor: "pointer", marginBottom: "100px" }}
        onClick={() => removeColumn(socket, layoutProps)}
      >
        <CloseCircleOutlined /> Delete Feed
      </div>
    </div>
  );
};

export default ColumnHeaderOption;
