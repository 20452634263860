import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../utility";

const initialState = {
  error: "",
  loading: false,
  data: null,
  token_expired: false,
};

export const fetch_user_details: any = createAsyncThunk(
  "user_data",
  async (arg, thunkApi) => {
    try {
      const res = await axiosInstance.get(`/auth/admin/getUserDetails/`);
      return thunkApi.fulfillWithValue(res.data);
    } catch (err: any) {
      return thunkApi.rejectWithValue(err.response);
    }
  }
);

export const userSlice = createSlice({
  name: "user_data",
  initialState,
  reducers: {
    check_if_token_is_expired: (state: any, action: any) => {
      const payload = action.payload;
      if (payload?.data?.error_type === "TOKEN_EXPIRED") {
        state.token_expired = true;
        state.loading = false;
        state.error = action.payload.message;
        state.data = null;
      }
    },
    update_user_details: (state: any, action: any) => {
      state.data = action.payload;
    },
    reset_user_details: (state: any) => {
      state.error = "";
      state.loading = false;
      state.data = null;
      state.token_expired = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetch_user_details.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetch_user_details.fulfilled, (state, action) => {
      state.token_expired = false;
      state.loading = false;
      state.error = "";
      state.data = action.payload;
    });
    builder.addCase(fetch_user_details.rejected, (state, action) => {
      const error_data = action.payload;
      if (error_data?.data?.error_type === "TOKEN_EXPIRED") {
        state.token_expired = true;
      }
      state.loading = false;
      state.error = action.payload.message;
      state.data = null;
    });
  },
});

export const {
  check_if_token_is_expired,
  update_user_details,
  reset_user_details,
} = userSlice.actions;
export default userSlice.reducer;
