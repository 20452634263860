import React from "react";
import { useSelector } from "react-redux";

import { Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";

import { LayoutProps } from "src/interface/PiqState";
import useColumnHeader from "../../../Hooks/useColumnHeader";
import { useAuthContext } from "src/context/AuthProvider";
import CollapsePanelRedioGroup from "../CollapsePanelRedioGroup";

const { Panel } = Collapse;

const EditPanel: React.FC<{
  socket: string;
  layoutProps: LayoutProps;
}> = ({ socket, layoutProps }) => {
  const darkMode = useSelector((state: any) => state?.user?.darkMode);
  const { partnerfeeds, piqnewsfeeds, premiumfeeds, twitterfeeds } = useSelector((state: any) => state.piqcolumns)

  const onEditChange = (oldCol: string, newCol: string) => {
    setShowSaveDialog(true);
    handleUpdateSaveColumn({ oldCol, newCol });
  };

  // console.log(layoutProps.piqSuiteLayout);

  const {
    handleUpdateSaveColumn,
    alreadySelectedDialog,
    setShowSaveDialog,
    setAlreadySelectedDialog,
  } = useColumnHeader(layoutProps);

  const socketNames = layoutProps.piqSuiteLayout?.map((item) => item.i);


  const { subscribedData }: any = useSelector(
    (state: any) => state.premiumapps
  );
  const newsQuawkSubscription =
    subscribedData.find(
      (item: any) => item?.premiumApps?.includes("Newsquawk") && item.active
    ) || {};
  const hasNewsQuawkSubscription =
    Object.keys(newsQuawkSubscription)?.length > 0 ? true : false;
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  return (
    <div
      className="v2-edit-panel"
      style={{ background: darkMode ? "#384251" : "#ffffff" }}
    >
      <Collapse
        accordion
        className="v2-edit-panel-collapse"
        bordered={false}
        expandIconPosition={"end"}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
      >
        <Panel
          header="Partner Feeds"
          key="1"
          style={{ border: "none", margin: "5px 0" }}
        >
          <CollapsePanelRedioGroup
            feeds={partnerfeeds}
            slugList={socketNames}
            hasNewsQuawkSubscription={hasNewsQuawkSubscription}
            socket={socket}
            onEditChange={onEditChange}
            isUserLoggedIn={isUserLoggedIn}

          />
        </Panel>

        <Panel
          header="PiQ X Lists"
          key="3"
          style={{ border: "none", marginBottom: "5px" }}
        >
          <CollapsePanelRedioGroup
            feeds={twitterfeeds}
            slugList={socketNames}
            hasNewsQuawkSubscription={hasNewsQuawkSubscription}
            socket={socket}
            onEditChange={onEditChange}
            isUserLoggedIn={isUserLoggedIn}

          />
        </Panel>

        <Panel
          header="PiQ News Feeds"
          key="4"
          style={{ border: "none", marginBottom: "5px" }}
        >

          <CollapsePanelRedioGroup
            feeds={piqnewsfeeds}
            slugList={socketNames}
            hasNewsQuawkSubscription={hasNewsQuawkSubscription}
            socket={socket}
            onEditChange={onEditChange}
            isUserLoggedIn={isUserLoggedIn}

          />
        </Panel>
        <Panel
          header="Premium Feeds"
          key="5"
          style={{ border: "none", marginBottom: "5px" }}
        >

          <CollapsePanelRedioGroup
            feeds={premiumfeeds}
            slugList={socketNames}
            hasNewsQuawkSubscription={hasNewsQuawkSubscription}
            socket={socket}
            onEditChange={onEditChange}
            isUserLoggedIn={isUserLoggedIn}

          />
        </Panel>
      </Collapse>

      <div
        hidden={!alreadySelectedDialog}
        className="edit-save-dialog"
        style={{ background: darkMode ? "black" : "#ffffff" }}
      >
        <div className="red-text">
          You already have this feed selected. Please choose another.
        </div>
        <button
          className="ok-dialog-btn"
          style={{ marginRight: "10px" }}
          onClick={() => setAlreadySelectedDialog(false)}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export default EditPanel;
