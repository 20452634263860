import { SingleKeywordColConfig, Singlekeyword } from "src/interface/keyword";
import { filter } from "src/interface/filter";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function removeLastUrl(inputString: string) {
  // Regular expression to match URLs in the input string
  const urlRegex = /(https?:\/\/\S+)|(\bwww\.[^\s]+)\b/gi;

  // Extract all URLs from the input string
  const urls = inputString.match(urlRegex) || [];

  // If there are URLs in the string, remove the last one
  if (urls.length > 1) {
    // Get the index of the last URL in the input string
    const lastIndex = inputString.lastIndexOf(urls[urls.length - 1]);

    // Remove the last URL from the input string
    const stringWithoutLastUrl = inputString.slice(0, lastIndex).trim();
    return stringWithoutLastUrl;
  }

  // If there's only one URL or no URLs, return the input string as it is
  return inputString.trim();
}
function highlightWordsWithDollarSign(inputString: string) {
  // Regular expression to match words attached to $
  // const regex = /\$\w+/g;

  // // Replace matched words with highlighted version
  // const highlightedString = inputString.replace(regex, (match) => {
  //   return `<span style='color:#66dbc4;cursor:pointer;font-size:10px;' >${match}</span>`;
  // });
  // return highlightedString;

  return inputString;
}
const escapeRegExp = (str: string) => {
  return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};
function highlightWordsWithATSign(inputString: string) {
  // Regular expression to match words attached to $
  // eslint-disable-next-line no-useless-escape
  const regex = /\@\w+/g;

  // Replace matched words with highlighted version
  const highlightedString = inputString.replace(regex, (match) => {
    return `<span style='color:#66dbc4;cursor:pointer;font-size:10px;' >${match}</span>`;
  });
  return highlightedString;
}

export const getKeywordHighlight = (
  text: string,
  colConfig: SingleKeywordColConfig
) => {
  // Split the text by '\n\n' and create an array of lines
  const highlightedString = colConfig?.singleWordPhraseKeywords?.reduce(
    (acc: string, keyword: Singlekeyword) => {
      const escapedKeyword = escapeRegExp(keyword.name);
      const regex = new RegExp(`\\b${escapedKeyword}\\b`, "gi");
      return acc.replace(
        regex,
        (match) =>
          `<span style="background-color: ${keyword.backgroundColor}; color: ${keyword.fontColor};">${match}</span>`
      );
    },
    text
  );
  return highlightedString;
};

export const checkIfDivHasHighlight = (text: string, colConfig: any) => {
  const hasHighlights = colConfig?.singleWordPhraseKeywords?.reduce(
    (acc: boolean, keyword: Singlekeyword) => {
      if (acc) {
        // If a match is already found, return true immediately
        return true;
      }
      const escapedKeyword = escapeRegExp(keyword.name);
      const regex = new RegExp(`\\b${escapedKeyword}\\b`, "gi");
      return regex.test(text);
    },
    false
  );
  return hasHighlights === true;
};

export const getDivHighlightColor = (text: string, colConfig: any) => {
  let firstBackgroundColor: string | boolean = false;

  if (text && colConfig?.singleWordPhraseKeywords) {
    for (const keyword of colConfig.singleWordPhraseKeywords) {
      const escapedKeyword = escapeRegExp(keyword.name);
      const regex = new RegExp(`\\b${escapedKeyword}\\b`, "gi");

      if (regex.test(text)) {
        firstBackgroundColor = keyword.backgroundColor;
        break;
      }
    }
  }

  return firstBackgroundColor;
};

export const decodeEntities = (
  encodedString: string,
  entities: any = null
): string => {
  // eslint-disable-next-line no-useless-escape
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const textArea = document.createElement("textarea");
  textArea.innerHTML = highlightWordsWithATSign(
    highlightWordsWithDollarSign(encodedString)
  )
    ?.replaceAll("\n\n", "\n")
    ?.replaceAll("\n", "<br/>");
  const withUrlText = textArea.value.replace(urlRegex, function (url) {
    // return "<a openNewWindowHref=" + url + "  >" + url + "</a>";
    if (entities) {
      const urls = entities?.urls;
      const tempUrl = urls?.find((item: any) => item.url === url);
      if (!tempUrl?.display_url) return "";
      return (
        "<span style='color:#66dbc4;cursor:pointer;font-size:10px;' openNewWindowHref=" +
        tempUrl?.url +
        "  >" +
        tempUrl?.display_url + //expanded_url
        "</span>"
      );
    } else {
      return (
        "<span style='color:#66dbc4;cursor:pointer;font-size:10px;' openNewWindowHref=" +
        url +
        "  >Click here ...</span>"
      );
    }
  });

  return withUrlText;
};

export const decodeDrawerEntities = (encodedString: string): string => {
  // eslint-disable-next-line no-useless-escape
  return encodedString.replaceAll("<a href=", "<a openNewWindowHref=");
};

export const decodeReutersEntities = (encodedString: string): string => {
  // eslint-disable-next-line no-useless-escape
  const urlRegex =
    // eslint-disable-next-line no-useless-escape
    /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
  const textArea = document.createElement("textarea");
  textArea.innerHTML = encodedString;
  let withUrlText = textArea.value.replace(urlRegex, function (url) {
    return "<img style='width:-webkit-fill-available' src=" + url + "  ></img>";
  });
  withUrlText = withUrlText?.replace("(PiQSuite.Com)", "(Reuters)");

  return withUrlText;
};
// target="_blank"

export const decodeTitle = (encodedString: string): string => {
  let tmp = document.createElement("DIV");
  tmp.innerHTML = encodedString;
  return tmp.textContent || tmp.innerText || "";
};
export const dynamicNewsImageGrid = (
  i: number,
  item: any,
  length: number = 0
) => {
  return {
    backgroundImage: `url(${item})`,
    gridRow: i === 0 && (length === 3 || length === 5) ? "1/3" : "",
    gridColumn: i === 0 && length === 5 ? "1/3" : "",
  };
};
export const dynamicTweetImageGrid = (
  i: number,
  item: any,
  length: number = 0
) => {
  return {
    backgroundImage: `url(${item?.media_url})`,
    gridRow: i === 0 && (length === 3 || length === 5) ? "1/3" : "",
    gridColumn: i === 0 && length === 5 ? "1/3" : "",
  };
};
export const getWindowDimensions: () => {
  width: number;
  height: number;
} = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
export const getScreenSize: () => "xs" | "sm" | "md" | "lg" | "xxs" = () => {
  const { width } = getWindowDimensions();
  if (width >= 480 && width < 768) {
    return "xs";
  } else if (width >= 768 && width < 996) {
    return "sm";
  } else if (width >= 996 && width < 1200) {
    return "md";
  } else if (width >= 1200) {
    return "lg";
  } else {
    return "xxs";
  }
};
export const dynamicStyle = (length: number, base: number = 70) => {
  switch (length) {
    case 1: {
      return {
        gridTemplateColumns: "repeat(1,1fr)",
        gridAutoRows: `${base * 2}px`,
      };
    }
    case 2:
      return {
        gridTemplateColumns: "repeat(2,1fr)",
        gridAutoRows: `${base * 2}px`,
      };
    case 3:
    case 4:
      return {
        gridTemplateColumns: "repeat(2,1fr)",
        gridAutoRows: `${base}px`,
      };
    case 5:
      return {
        gridTemplateColumns: "repeat(4,1fr)",
        gridAutoRows: `${base}px`,
      };
    default:
      return {
        gridTemplateColumns: "repeat(2,1fr)",
        gridAutoRows: `${base}px`,
      };
  }
};

export const replaceItemInArray = (
  inputArray: any,
  index: number,
  substitutionElem: any
) => {
  return inputArray.map((item: any, key: number) => {
    if (index === key) {
      return substitutionElem;
    }
    return item;
  });
};

// utils.js
// utils.js
export function removeHTMLTags(description: string, charLimit: number) {
  if (!description) return "";
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = description;
  let plainText = tempDiv.textContent || tempDiv.innerText || "";
  plainText = plainText.replace(/\n/g, "");
  plainText = plainText.replace(/ {2}/g, "");

  if (charLimit && plainText.length > charLimit) {
    plainText = plainText.substring(0, charLimit);
  }

  return plainText;
}

// export function createNewPage() {
//   window.InvestingChannelQueue.push(() => {
//     window.ic_page = window.InvestingChannel.UAT.Run(
//       "3b249b9f-5e53-4015-9dc0-dee2c833f04e"
//     );
//   });
// }

// export function removeAds() {
//   window.InvestingChannelQueue.push(function () {
//     window.ic_page.destroy();
//   });
// }

// function createRegexFromFilters(filters: filter[]) {
//   return filters.reduce((regexStr, filter) => {
//     const { keyword, boolean } = filter;
//     const newRegex = new RegExp(keyword, "gi"); // Create a case-insensitive regex
//     console.log("filter asds", keyword, boolean);
//     if (regexStr) {
//       switch (boolean) {
//         case "AND":
//           return `${regexStr}(?=.*${newRegex.source})`;
//         case "OR":
//           return `${regexStr}|${newRegex.source}`;
//         case "NOT":
//           return `(?!${regexStr}).*${newRegex.source}`; // Negative lookahead for NOT
//         default:
//           throw new Error("Invalid boolean operator");
//       }
//     } else {
//       return newRegex.source;
//     }
//   }, "");
// }

function getFilteredList(list: any[], filters: filter[]) {
  filters = filters?.filter((item) => item?.keyword !== "");
  const andFilters = filters
    .filter((f) => f.boolean === "AND")
    .map((f) => f.keyword.toLowerCase());
  const orFilters = filters
    .filter((f) => f.boolean === "OR" || f.boolean === "ONLY")
    .map((f) => f.keyword.toLowerCase());
  const notFilters = filters
    .filter((f) => f.boolean === "NOT")
    .map((f) => f.keyword.toLowerCase());

  const matchesAndFilters = (item: any, keywords: string[]) =>
    keywords.every((keyword) => {
      const textToSearch =
        item.title?.toLowerCase() ||
        decodeEntities(item.full_text, item.entities)?.toLowerCase();
      return textToSearch.includes(keyword);
    });

  const matchesOrFilters = (item: any, keywords: string[]) =>
    keywords.some((keyword) => {
      const textToSearch =
        item.title?.toLowerCase() ||
        decodeEntities(item.full_text, item.entities)?.toLowerCase();
      return textToSearch.includes(keyword);
    });

  const matchesNotFilters = (item: any, keywords: string[]) =>
    keywords.every((keyword) => {
      const textToSearch =
        item.title?.toLowerCase() ||
        decodeEntities(item.full_text, item.entities)?.toLowerCase();
      return !textToSearch.includes(keyword);
    });

  let filteredList = list;

  if (andFilters.length > 0) {
    filteredList = filteredList.filter((item) =>
      matchesAndFilters(item, andFilters)
    );
  }

  if (
    orFilters.length > 0
    // && andFilters?.length === 0
  ) {
    const orFilteredList = list.filter((item) =>
      matchesOrFilters(item, orFilters)
    );
    filteredList = filteredList.filter((item) => orFilteredList.includes(item));
  }

  if (notFilters.length > 0) {
    filteredList = filteredList.filter((item) =>
      matchesNotFilters(item, notFilters)
    );
  }

  return filteredList;
}

export const filterColFeed = (list: any[], filters: filter[]) => {
  if (
    (filters?.length === 1 && filters?.[0]?.keyword === "") ||
    filters?.length === 0
  )
    return list;

  const filteredList = getFilteredList(list, filters);
  return filteredList;
};
