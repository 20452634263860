import React from "react";
import { useState } from "react";

import lockIcon from "src/style/images/Padlock.svg";
import TradingViewLogo from "src/style/images/Trading_View_Full_Logo.svg";
import { useAuthContext } from "src/context/AuthProvider";
import { GlobalProps, LayoutProps, SocketNames } from "src/interface/PiqState";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import TradingViewAdvancedWidget from "./TradingViewAdvanceWidgets";

export const TradingViewComponent: React.FC<{
  socket: SocketNames;
  props: GlobalProps;
  layoutProps: LayoutProps;
}> = ({ socket, props, layoutProps }) => {
  const { isSignedIn: isUserLoggedIn } = useAuthContext();

  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);

  const [isFetching, setFetching] = useState<boolean>(false);

  const refresh_function = () => {
    setFetching(true);
    const time = setTimeout(() => {
      setFetching(false);
    }, 1000);
    return () => clearTimeout(time);
  };

  return (
    <>
      <div className={`view-chart-div `}

      >
        <ColumnHeader
          title={"TradingView"}
          socket={socket}
          isFetching={isFetching}
          fetchFunction={() => refresh_function()}
          handleEditPanel={() => setShowEditPanel(true)}
        />
        {showEditPanel && socket ? (

          <ColumnHeaderOption
            props={props}
            setShowEditPanel={setShowEditPanel}
            socket={socket}
            layoutProps={layoutProps}
          />
        ) : (
          <>

            <TradingViewAdvancedWidget />
          </>
        )}
      </div>
      {/* overlay  */}
      {/* {!isUserLoggedIn && !showEditPanel ? (
        <>
          <div
            className="overlay-blur"
            style={{ height: "calc(100% - 26px)" }}
            onClick={() => props?.setActiveDrawer("upgrade-panel-drawer")}
          >
            <div className="overlay-reuter-contain">

              <div>
                <img src={TradingViewLogo} alt="" className="lock-log"

                  style={{
                    marginLeft: '190px', width: 200,
                    height: 26.178
                  }}
                />

              </div>
              <div className="lock-text">
                add TradingView Charts <br /> to your <span>PiQ Suite</span> Dashboard
              </div>
              <div>

                <img src={lockIcon} alt="" className="lock-icon"
                  style={{ marginTop: '65px' }}
                />

              </div>
              <div className="lock-signup">
                <span
                  onClick={() => props?.setActiveDrawer("upgrade-panel-drawer")}
                  className="signup-text">Sign up</span> for <span>FREE</span> to unlock
              </div>
            </div>

          </div>

        </>
      ) : (
        ""
      )} */}
    </>
  );
};
