import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { axiosInstance } from "../utility";

// api endpoint integration that accepts payload as event:
// 1. Signup with email button click
// 2. Each Sign up form field completion
// 3. Sign up form button click
// 4. Registration Drawer open event

export const pushTrackerEvent: any = createAsyncThunk(
  "tracker/track",
  async (payload) => {
    const res = await axiosInstance.post(`/events`, payload);
    return res;
  }
);

const initialState = {
  data: {
    activeSessionTime: new Date().getTime(),
  },
};

export const trackerSlice = createSlice({
  name: "tracker",
  initialState,
  reducers: {
    resetSessionTime: (state: any) => {
      state.data.activeSessionTime = new Date().getTime();
    },
    // resetSessionTime: (state: any) => {
    //   console.log("times resetting", new Date().getTime());
    //   return {
    //     ...state,
    //     data: {
    //       ...state.data,
    //       activeSessionTime: new Date().getTime(),
    //     },
    //   };
    // },
  },

  extraReducers: (builder) => {
    builder
      .addCase(pushTrackerEvent.pending, (state) => {
        // for any future additions
      })
      .addCase(pushTrackerEvent.fulfilled, (state) => {
        // for any future additions
        resetSessionTime();
      });
  },
});

export const { resetSessionTime } = trackerSlice.actions;

export default trackerSlice.reducer;
