import React, { useEffect, useState } from "react";
import lockIcon from "src/style/images/Padlock.svg";

import { useAuthContext } from "src/context/AuthProvider";
import { getpiqvideofeedBySlug } from "src/store/piqtv/action";
import { getYoutubeId } from "src/utils/common";
import { useSelector } from "react-redux";
import VideoColumnHeader from "src/development/Component/ColumnHeader/Video-Column-Header";
import IPTVPLayer from "src/development/Component/IPTVPlayer/IPTVPlayer";
import VideoEditPanel from "src/development/Component/ColumnHeader/VideoEditPanel";
import ColumnLoading from "src/development/Component/Loading";
import useColumnHeader from "src/Hooks/useColumnHeader";
import { GlobalProps, LayoutProps } from "src/interface/PiqState";
import logo from "src/style/images/icon-youtube.svg";
export const VideoPlayer: React.FC<{
  socket: string;
  props: GlobalProps;
  layoutProps: LayoutProps;
}> = ({ socket, props, layoutProps }) => {
  const [showOptions, setShowOptions] = useState(false);
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const [data, setData] = useState<any>(null);
  const { removeColumn } = useColumnHeader(layoutProps);
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));

  const fetch_function = () => {
    getpiqvideofeedBySlug(socket)
      .then((res) => {
        setData(res.data);
      })
      .catch((err: any) => {
        removeColumn(socket, layoutProps);
      });
  };
  const refresh_function = () => {
    setData(null);
    fetch_function();
  };

  useEffect(() => {
    fetch_function();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket]);

  return (
    <>
      <div className={`video-player `}>
        <VideoColumnHeader
          isFetching={data == null}
          fetchFunction={() => refresh_function()}
          title={data?.title}
          handleVideoEditPanel={() => setShowOptions(true)}
        />
        <div className="video-player-div">
          {data ? (
            <>
              {!showOptions ? (
                data?.type === "iptv" ? (
                  // isUserLoggedIn ?
                  <div
                    className="v2-video-contatiner"
                    key={isUserLoggedIn ? 1 : 2}
                    style={{ background: darkMode ? "#384251" : "#fff" }}
                  >
                    <IPTVPLayer
                      autoplay={false}
                      controls={true}
                      sources={data?.url}
                    />
                  </div>
                ) : // : <></>
                  data?.type === "youtube" ? (
                    <>
                      <div
                        className={`hytPlayerWrap v2-video-contatiner 
                      ${!isUserLoggedIn ? "blur-effect" : ""}
                      `}
                        style={{ background: darkMode ? "#384251" : "#fff" }}
                        key={isUserLoggedIn ? 1 : 2}
                      >
                        <iframe
                          style={{ paddingBottom: 20 }}
                          className="hytPlayerWrap"
                          src={`https://www.youtube.com/embed/${getYoutubeId(
                            data?.url
                          )}`}
                          title={data?.title}
                          // frameborder={"0"}
                          allowFullScreen
                          height={"100%"}
                          width={"100%"}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={`hytPlayerWrap v2-video-contatiner ${!isUserLoggedIn ? "blur-effect" : ""
                          }`}
                        style={{ background: darkMode ? "#384251" : "#fff" }}
                        key={isUserLoggedIn ? 1 : 2}
                      >
                        <iframe
                          style={{ paddingBottom: 20 }}
                          className="hytPlayerWrap"
                          src={data?.url}
                          title={data?.title}
                          // frameborder={"0"}
                          allowFullScreen
                          height={"100%"}
                          width={"100%"}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></iframe>
                      </div>
                    </>
                  )
              ) : (
                <></>
              )}
            </>
          ) : (
            <ColumnLoading />
          )}

          <div
            className={`${""} v2-video-edit-panel`}
            style={{ height: "100%" }}
          >
            <VideoEditPanel
              title={data?.title}
              setShowVideoEditPanel={setShowOptions}
              socket={socket}
              layoutProps={layoutProps}
            />
          </div>
        </div>
      </div>

      {/* overlay  */}
      {/* {!isUserLoggedIn && !showOptions ? (
        <div
        >
          <div
            className="overlay-blur"
            style={{ height: "calc(100% - 26px)" }}
            onClick={() => props?.setActiveDrawer("upgrade-panel-drawer")}
          >
            <div className="overlay-reuter-contain" style={{ width: "100%" }}>
              <div>
                <img
                  style={{ width: "60px", height: "auto", marginLeft: '53px' }}
                  src={logo} alt="" className="lock-log"
                />
              </div>
              <div className="lock-text">
                watch curated News & Analysis <br /> on your <span>PiQ Suite</span> Dashboard
              </div>

              <div style={{ marginTop: '20px' }}>

                <img src={lockIcon}
                  alt="" className="lock-icon" />

              </div>
              <div className="lock-signup">
                <span
                  onClick={() => props?.setActiveDrawer("upgrade-panel-drawer")}
                  className="signup-text">Sign up</span> for <span>FREE</span> to unlock
              </div>
            </div>
          </div>

        </div>
      ) : (
        ""
      )} */}
    </>
  );
};
