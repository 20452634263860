import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import { activeDrawer } from "src/interface/PiqState";

const TCComponent = lazy(() => import("../pages/TC"));
const PPComponent = lazy(() => import("../pages/PP"));
const CCPA = lazy(() => import("../pages/ccpa"));
const YesCookies = lazy(() => import("../pages/yesCookies"));
const WebDashboard = lazy(
    () => import("src/development/Container/Web/Dashboard")
);
const PagesRoute: React.FC<{
    activeDrawerState: activeDrawer;
    oldActiveDrawerState: activeDrawer;
    setActiveDrawer: (key: activeDrawer) => any;
    piqSuiteLayout: any[];
    setPiqSuiteLayout: (layouts: any) => any;
}> = ({
    activeDrawerState,
    setActiveDrawer,
    piqSuiteLayout,
    setPiqSuiteLayout,
    oldActiveDrawerState
}) => {
        return (
            <>
                <Route
                    path="/"
                    exact={true}
                    render={(props) =>
                    (
                        <WebDashboard
                            props={{
                                oldActiveDrawerState: oldActiveDrawerState,
                                activeDrawerState: activeDrawerState,
                                setActiveDrawer: (key: activeDrawer) => setActiveDrawer(key),
                            }}
                            layoutProps={{
                                piqSuiteLayout,
                                setPiqSuiteLayout: (layouts: any) => setPiqSuiteLayout(layouts),
                            }}
                        />
                    )
                    }
                />
                <Route exact={true} path="/ccpa" component={CCPA} />
                <Route
                    exact={true}
                    path="/terms-and-conditions"
                    component={TCComponent}
                />
                <Route exact={true} path="/privacy-policy" component={PPComponent} />
                <Route exact={true} path="/yes-cookies" component={YesCookies} />
                <Route exact path="/suite">
                    <Redirect to="/" />
                </Route>
                <Route exact path="/reuters">
                    <Redirect to="/" />
                </Route>
                <Route exact path="/suite/reuters">
                    <Redirect to="/" />
                </Route>

                <Route
                    path="/:route/:routeSlug"
                    exact={true}
                    render={(props) =>
                    (
                        <WebDashboard
                            props={{
                                activeDrawerState: activeDrawerState,
                                oldActiveDrawerState: oldActiveDrawerState,

                                setActiveDrawer: (key: activeDrawer) => setActiveDrawer(key),
                            }}
                            layoutProps={{
                                piqSuiteLayout,
                                setPiqSuiteLayout: (layouts: any) => setPiqSuiteLayout(layouts),
                            }}
                        />
                    )
                    }
                />


            </>
        );
    };

export default PagesRoute;
