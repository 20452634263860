import { GlobalProps, LayoutProps, SocketNames } from "src/interface/PiqState";
import { getWebFeedComponent } from "./Component";

export type componentType =
  | "NEWS_FEED_COMPONENT"
  | "TWITTER_FEED_COMPONENT"
  | "VIDEO_FEED_COMPONENT"
  | "CHART_FEED_COMPONENT"
  | "APP_FEED_COMPONENT"
  | "PREMIUM_FEED_COMPONENT";
export type feedType = "NEWS_FEED" | "VIDEO_FEED" | "CHART_FEED";

export type appSelectorTypes =
  | "PARTNER_FEED"
  | "PIQ_FEED"
  | "CHART_FEED"
  | "TWITTER_FEED"
  | "VIDEO_FEED";
export interface Column {
  title: string;
  icon?: any;
  socket: SocketNames;
  reducer?: string;
  twitterAccount?: string;
  feedType?: feedType;
  componentType?: componentType;
  showToTabs: boolean;
  active: boolean;
  appSelectorTypes: appSelectorTypes;
}

export const defaultActiveWebColumn: any[] = [
  { col: "all-news", feedType: "NEWS_FEED" },
  { col: "reuters", feedType: "NEWS_FEED" },
  { col: "piqfeeds", feedType: "CHART_FEED" },
  { col: "cme", feedType: "CHART_FEED" },
  { col: "squawks-tweets", feedType: "TWITTER_FEED" },
  { col: "bloomberg", feedType: "VIDEO_FEED" },
  { col: "tradingview", feedType: "CHART_FEED" },
  { col: "telegram", feedType: "VIDEO_FEED" },
  { col: "forexLive", feedType: "NEWS_FEED" },
  { col: "investing-economic-calender", feedType: "CHART_FEED" },
];

export const itemsToRemove: string[] = ["videoFeed"];
export const defaultActiveMobileColumn: string[] = [
  "reuters",
  "cme",
  "market-tweets",
  "all-news",
];
export const removeComponentFromMobileView = ["bloomberg", "tradingview"];

export const columnCordinatesMappings: any = {
  "all-news": {
    coordinates: [0, 0],
    isVideo: false,
  },
  bloomberg: {
    coordinates: [1, 0],
    isVideo: false,
  },
  piqfeeds: {
    coordinates: [1, 3],
    isVideo: true,
  },
  "squawks-tweets": {
    coordinates: [2, 3],
    isVideo: true,
  },
  tradingview: {
    coordinates: [3, 0],
    isVideo: true,
  },
  cme: {
    coordinates: [5, 4],
    isVideo: true,
  },
  forexLive: {
    coordinates: [6, 0],
    isVideo: false,
  },
  "investing-economic-calender": {
    coordinates: [6, 7],
    isVideo: false,
  },
  telegram: {
    coordinates: [7, 0],
    isVideo: true,
  },
  reuters: {
    coordinates: [3, 4],
    isVideo: true,
  },
  // bloomeberg: {
  //   coordinates: [3, 10],
  //   isVideo: true,
  // },

  // 'investing-economic-calender': {
  //   coordinates: [3, 0],
  //   isVideo: true,
  // },

  default: {
    coordinates: [3, 0],
    isVideo: false,
  },
};

export const defaultColumnLayoutConfigs: any[] = [
  {
    i: "fastreveal",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "newsbtc",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },

  {
    i: "stock-traders-almanac",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "bitcoinist",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "hilltowerresourceadvisors",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },

  {
    i: "cme",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "forexanalytix",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "mi2partners",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "mtsinsights",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "audiomercados",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "econostream",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "forexLive",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 7,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "emergingMarketWatch",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isDraggable: true,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "harkster",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 8,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "capital",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "piqfeeds",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 8,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "reuters",
    minW: 1,
    maxW: 2,
    w: 2,
    minH: 3,
    h: 8,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "market-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "geopolitics-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "uspolitics-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "ukpolitics-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "weather-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "crypto-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "squawks-tweets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 8,
    maxH: Infinity,
    isDraggable: true,
    moved: false,
    static: false,
    isResizable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "tradingview",
    minW: 2,
    minH: 3,
    w: 3,
    h: 4,
    maxW: 6,
    maxH: 8,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "investing-economic-calender",
    minW: 3,
    minH: 4,
    w: 3,
    h: 4,
    maxW: 3,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: ["se", "s"],
  },
  {
    i: "complete-intel",
    minW: 2,
    minH: 3,
    w: 2,
    h: 6,
    maxW: 2,
    maxH: 10,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      // "se",
      "s",
    ],
  },
  {
    i: "telegram",
    minW: 2,
    minH: 5,
    w: 2,
    h: 7,
    maxW: 5,
    maxH: 25,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: ["se", "s"],
  },
  {
    i: "piqPoliticsChina",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "sports",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqPoliticsUs",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "politicsUK",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqPoliticsEurope",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "cryptoTimes",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "creditNews",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "newsquawk-delay",
    minW: 1,
    maxW: 2,
    w: 2,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: ["s", "se"],
    isBounded: true,
  },
  {
    i: "newsquawk",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: ["s", "se"],
    isBounded: true,
  },
  {
    i: "tickerApp",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "sinocism",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqCommoditiesMarkets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "market-energy",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqFXMarkets",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "stocks-data",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "all-news",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 11,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "customFeed1",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "customFeed2",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "customFeed3",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "customFeed4",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "customFeed5",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "brexit",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "politics123",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqAllMarket",
    minW: 1,
    maxW: 2,
    w: 2,
    minH: 3,
    h: 4,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqCrypto",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "piqCentralBank",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
    isBounded: true,
  },
  {
    i: "NEWS_FEED_DEFAULT",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    maxH: Infinity,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "APP_FEED_COMPONENT",
    minW: 1,
    maxW: 2,
    w: 1,
    minH: 3,
    h: 6,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "bloomberg",
    h: 3,
    w: 2,
    minH: 3,
    minW: 2,
    maxW: 4,
    maxH: 6,

    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },

  {
    i: "VIDEO_FEED_DEFAULT",
    h: 3,
    w: 2,
    minH: 3,
    minW: 2,
    maxW: 4,
    maxH: 6,

    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      "s",
      // "w", "e",
      // "sw",
      "se",
    ],
  },
  {
    i: "piqpodcast",
    h: 2,
    w: 3,
    minH: 2,
    minW: 3,
    maxW: 5,
    maxH: 2,
    moved: false,
    static: false,
    isResizable: true,
    isDraggable: true,
    resizeHandles: [
      // "s",
      // // "w", "e",
      // // "sw",
      // "se",
    ],
  },
];

export const generateCoordinates = (
  colString: string,
  type: string = "NEWS_FEED_COMPONENT"
) => {
  const { coordinates } = columnCordinatesMappings.hasOwnProperty(colString)
    ? columnCordinatesMappings[colString]
    : columnCordinatesMappings.default;
  var colConfigs;
  if (type === "NEWS_FEED_COMPONENT" || type === "TWITTER_FEED_COMPONENT") {
    colConfigs =
      defaultColumnLayoutConfigs.filter((col) => col.i === colString).length > 0
        ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
        : defaultColumnLayoutConfigs.find(
          (col) => col.i === "NEWS_FEED_DEFAULT"
        );

  } else if (type === "APP_FEED_COMPONENT") {
    colConfigs =
      defaultColumnLayoutConfigs.filter((col) => col.i === colString).length > 0
        ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
        : defaultColumnLayoutConfigs.find(
          (col) => col.i === "APP_FEED_COMPONENT"
        );
  } else if (type === "PODCAST_COMPONENT") {
    colConfigs =
      defaultColumnLayoutConfigs.filter((col) => col.i === colString).length > 0
        ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
        : defaultColumnLayoutConfigs.find(
          (col) => col.i === "piqpodcast"
        );
  } else {
    colConfigs = defaultColumnLayoutConfigs.filter((col) => col.i === colString)
      ?.length
      ? defaultColumnLayoutConfigs.find((col) => col.i === colString)
      : defaultColumnLayoutConfigs.find(
        (col) => col.i === "VIDEO_FEED_DEFAULT"
      );
  }

  return {
    ...{
      minW: colConfigs?.minW,
      maxW: colConfigs?.maxW,
      minH: colConfigs?.minH,
      maxH: colConfigs?.maxH,
      moved: colConfigs?.moved,
      static: colConfigs?.static,
      isResizable: colConfigs?.isResizable,
      isDraggable: colConfigs?.isDraggable,
      resizeHandles: colConfigs?.resizeHandles,
      w: colConfigs?.w,
      h: colConfigs?.h,
      type: type,
    },
    x: coordinates[0],
    y: coordinates[1],
    i: colString,
    // ...colConfigs,
  };
};

export const defaultColumnCoordinates: any[] = defaultActiveWebColumn.map(
  (item: any) => generateCoordinates(item.col, item.feedType)
);

export const customFeedsSockets = ['customFeed1', 'customFeed2', 'customFeed3', 'customFeed4', 'customFeed5']
export const customFeedsTitles = ['Custom Feed 1', 'Custom Feed 2', 'Custom Feed 3', 'Custom Feed 4', 'Custom Feed 5']

export const getColumnComponents = (
  colString: SocketNames,
  props: GlobalProps,
  layoutProps: LayoutProps,
  Columns: any,
  customeFeeds: any
) => {
  let column;
  if (customFeedsSockets.some(feed => colString.includes(feed))) {
    column = customeFeeds?.find((item: any) => item?.slug === colString)
  } else {
    column = Columns?.find((item: any) => item?.slug === colString)
  }
  if (!Columns?.length) return
  var webFeedComponent = getWebFeedComponent(colString, props, layoutProps, column);

  // if (!webFeedComponent) {
  //   webFeedComponent = {
  //     socket: colString,
  //     component: (
  //       <VideoPlayer
  //         socket={colString}
  //         props={props}
  //         layoutProps={layoutProps}
  //       />
  //     ),
  //   };
  // }
  const { component }: any = webFeedComponent;
  return component;
};