/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import { runSocketConnect } from "src/Socket";
import { setWindowSize } from "src/store/PIQ/action";
import { openNewWindow } from "src/utils/common";
import { useAuthContext } from "src/context/AuthProvider";
import piqLogo from "src/style/images/piq-logo-v2.svg";
import { Switch } from "react-router-dom";
import AppLayout from "./development/Component/AppLayout";
import {
  initGA,
  trackingPageGA,
} from "./development/Component/googleAnalytics4";
import { notification } from "antd";
import { RTM } from "./development/Component/RTM";
import { socket as socketInstance } from "src/Socket";
import * as Sentry from "@sentry/react";
import { isLocalhost } from "./serviceWorker";
import { activeDrawer } from "./interface/PiqState";
import { defaultColumnLayoutConfigs } from "./SuiteConfig/columnConfiguration";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { editArticleListConfig } from "./store/User/action";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Auth } from "aws-amplify";
import MetaTags from "./MetaTags";
import { fetchCustomFeedData, fetchpiqcolumnsData } from "./store/PiQColumns";
import { fetchInFeedAdsData } from "./store/InFeedAds";
import useDeviceInfo from "./Hooks/useDeviceInfo";
import { DeviceInfoContext } from "./context/DeviceInfoProvider";
import { isNull, some } from "lodash";
import useTracker from "./Hooks/useTracker";
import { resetSessionTime } from "./store/Tracker";
import useTabClosed from "./Hooks/useTabFocused";
import { BookmarkProvider } from "./context/BookmarkProvider";

const API_URL = process.env.REACT_APP_API_URL;

function App() {
  const dispatch = useDispatch();
  const [oldActiveDrawerState, setOldActiveDrawerState] =
    React.useState<activeDrawer>("");
  const [activeDrawerState, setActiveDrawerState] =
    React.useState<activeDrawer>("");
  const authContext: any = useAuthContext();
  const [piqSuiteLayout, setPiqSuiteLayout] = React.useState<any[]>([]);
  const [isWindowLoaded, setIsWindowLoaded] = React.useState<boolean>(false);
  const { handleDrawerOpen } = useTracker()
  const handleActiveDrawerChange = (activeDrawer: activeDrawer) => {
    handleDrawerOpen(activeDrawer)
    setActiveDrawerState(activeDrawer)
  }

  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));

  const Columns = useSelector((state: any) => {
    return state?.piqcolumns?.columns

  })

  const { sendDeviceInfo, sendActiveSessionDuration } = useTracker();

  const tracker = useSelector((state: any) => state)

  const deviceInfo = useContext(DeviceInfoContext);
  // useTabClosed();

  // useEffect(() => {
  //   sendActiveSessionDuration()
  // }, [])

  // useEffect(() => {
  //   (
  //     async () => {
  //       console.log("isTabFocused", isTabClosed, tracker)
  //       if (isTabClosed) {
  //         // store 
  //         // dispatch(resetSessionTime())
  //         await sendActiveSessionDuration();
  //       }
  //       // else {
  //       //   // get the duration of active session and send it to backend through event
  //       //   sendActiveSessionDuration()
  //       // }
  //     }
  //   )()

  // }, [isTabClosed])

  // useEffect(() => {
  //   const hasNullValue = some(deviceInfo, (value) => isNull(value));
  //   if (!hasNullValue) {
  //     console.log("deviceInfo = ", deviceInfo)
  //     // push event to backend with device data
  //     // sendDeviceInfo()
  //   }
  // }, [deviceInfo])


  useEffect(() => {
    let oldState = oldActiveDrawerState;
    let newState = activeDrawerState;

    if (oldState === "" && newState === "article-list-drawer") {
      const existingDev = document.getElementById("ic_d_div_OOP_1");
      if (existingDev) {
        window.removeAds();
      }
    } else if (
      newState === "article-list-drawer" &&
      oldState === "article-details-drawer"
    ) {
      window.removeAds();
    } else if (oldState === "article-list-drawer" && newState === "") {
      window.removeAds();
      window.createNewPage();
    } else if (
      oldState === "article-details-drawer" &&
      newState === "article-list-drawer"
    ) {
      // window.removeAds();
      // window.history.pushState({}, "", "/");
    } else if (
      oldState === "article-list-drawer" &&
      newState === "article-details-drawer"
    ) {
      // window.removeElement("ic_d_div_728x90_1");
      window.removeAds();
    } else if (newState === "article-details-drawer" && oldState === "") {
      // try {
      //   window.removeAds();
      // } catch (e) {
      //   console.log("failed to remove since no ads");
      // }
      // window.createNewPage();
    }
  }, [activeDrawerState]);

  const handleLayoutChange = async (newLayout: any[]) => {
    const tempLayout = await Promise.all(
      newLayout?.map(async function (item: any, key: any, list: any) {
        var colConfigs = defaultColumnLayoutConfigs.find(
          (x: any) => x.i === item?.i
        );
        if (!colConfigs) {
          colConfigs = defaultColumnLayoutConfigs.find(
            (x: any) => x.i === "VIDEO_FEED_DEFAULT"
          );
        }
        let completeIntelligence = false;
        // if (colConfigs.i === "complete-intel") {
        //   await axiosInstance
        //     .get(`${API_URL}/completeintelligence/get`)
        //     .then((res) => {
        //       if (res?.data?.status) {
        //         const { piqAppPermission, piqTermsCondition } = res?.data?.data;
        //         if (piqAppPermission && piqTermsCondition) {
        //           completeIntelligence = true;
        //         } else {
        //           completeIntelligence = false;
        //         }
        //       }
        //     });
        // }

        return {
          ...{
            completeIntegilence: completeIntelligence,
            i: item?.i,
            minW: colConfigs?.minW || 1,
            maxW: colConfigs?.maxW || 2,
            maxH: colConfigs?.maxH || 10,
            minH: colConfigs?.minH || 1,
            moved: colConfigs?.moved,
            static: colConfigs?.static,
            isResizable: colConfigs?.isResizable,
            isDraggable: colConfigs?.isDraggable,
            resizeHandles: colConfigs?.resizeHandles,
            w: item?.w || 1,
            h: item?.h || 2,
            x: item?.x,
            y: item?.y,
            type:
              Columns.find((x: any) => x?.slug === item?.i)?.componentType ||
              (item?.i?.includes("tradingview") && "CHART_FEED_COMPONENT") ||
              "VIDEO_FEED_COMPONENT",
          },
        };
      })
    );

    setPiqSuiteLayout(tempLayout);
  };



  useEffect(() => {
    dispatch(fetchpiqcolumnsData())
    dispatch(fetchCustomFeedData())
    dispatch(fetchInFeedAdsData())
    trackingPageGA(window.location.pathname + window.location.search);
    initGA();
    const { innerWidth: width, innerHeight: height } = window;
    dispatch(setWindowSize([width, height]) as any);
    runSocketConnect("");


  }, []);




  useEffect(() => {
    const handleWindowResize = () => {
      if (window?.innerWidth <= 700) {
        handleActiveDrawerChange("article-list-drawer");
      }
      dispatch(setWindowSize([window.innerWidth, window.innerHeight]) as any);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      // openNewWindow('https://www.example.com');
      const target = event?.target as HTMLElement;
      const openNewWindowHref = target.getAttribute("openNewWindowHref");
      if (openNewWindowHref) {
        openNewWindow(openNewWindowHref);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  useEffect(() => {
    if (authContext?.user?.username) {
      runSocketConnect(authContext?.user?.attributes?.email);
    } else {
      runSocketConnect("");
    }
    //eslint-disable-next-line
  }, [authContext?.user]);

  useEffect(() => {
    return () => {
      dispatch(
        editArticleListConfig({ tabKey: "reuters", localPage: 1 }) as any
      );

    };
  }, []);








  useEffect(() => {
    async function hello() {
      await Auth.currentAuthenticatedUser();
    }
    hello();
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      // Perform actions before the component unloads
      dispatch(
        editArticleListConfig({ tabKey: "reuters", localPage: 1 }) as any
      );
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    const onData = (data: any) => {
      notification.open({
        message: "",
        key: data?._id,
        description: (
          <RTM
            data={data}
            dispatch={dispatch}
            notification={notification}
            props={{
              activeDrawerState: activeDrawerState,
              setActiveDrawer: (key: activeDrawer) => handleActiveDrawerChange(key),
              oldActiveDrawerState: oldActiveDrawerState,
            }}
            activeDrawerState={activeDrawerState}
            setActiveDrawer={(key: activeDrawer) => handleActiveDrawerChange(key)}
          />
        ),
        duration: 0,
        style: {
          backgroundColor: "#EE5E49",
          width: 500,
          padding: "15px",
          paddingTop: "10px",
        },
      });
    };

    socketInstance.on("rtm", onData);

    return () => {
      socketInstance.off("rtm", onData);
    };
  });

  useEffect(() => {
    isLocalhost
      ? console.log("sentry not init")
      : Sentry.init({
        dsn: "https://2e30ba8c60453be8112e9549a3cf1272@sentry.corp.black/18",
        integrations: [
          new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [
              "localhost",
              "https://api.priapusiq.com",
              "https://piqsuite.com/",
            ],
            // _metricOptions: {
            //   _reportAllChanges: true,
            // }
          }),
          new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 1.0,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,
      });
  }, []);

  useEffect(() => {
    // Initialize FirstPromoter
    (window as any).fpr =
      (window as any).fpr ||
      function () {
        ((window as any).fpr.q = (window as any).fpr.q || []).push(arguments);
      };
    (window as any).fpr("init", { cid: "dyafflt1" });
    (window as any).fpr("click");

    const script = document.createElement("script");
    script.src = "https://cdn.firstpromoter.com/fpr.js";
    script.async = true;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    setIsWindowLoaded(true);
  }, []);

  if (isWindowLoaded) {
    if (typeof window !== "undefined") {
      // if (window?.innerHeight < 700 && window?.innerWidth < 750) {
      const pathname = window.location.pathname;
      if (window?.innerWidth < 750) {
        window.location.href = `https://m.piqsuite.com/${pathname}`;
        return null; // Return null to prevent rendering anything
      }
    }

    return (
      <Suspense

        fallback={
          <div className="suspension-div">
            <img src={piqLogo} alt="" />
          </div>
        }
      >
        <MetaTags
          title="PiQ Suite: News & Data for Traders"
          description="PiQ Suite aggregates news, data, social media feeds, and apps from over 100 sources. Try PiQ Suite for free today."
        />
        <main>
          <Switch>
            <GoogleOAuthProvider clientId="682542158674-5bug0qulqf447mevr2bdb66pq9c5iehv.apps.googleusercontent.com">
              <BookmarkProvider
                setActiveDrawer={(key: activeDrawer) => {
                  setOldActiveDrawerState(activeDrawerState);
                  handleActiveDrawerChange(key);
                }
                }
              >
                <AppLayout
                  props={{
                    activeDrawerState: activeDrawerState,
                    setActiveDrawer: (key: activeDrawer) => {
                      setOldActiveDrawerState(activeDrawerState);
                      handleActiveDrawerChange(key);
                    },
                    oldActiveDrawerState: oldActiveDrawerState,
                  }}
                  layoutProps={{
                    piqSuiteLayout,
                    setPiqSuiteLayout: (layouts: any[]) =>
                      handleLayoutChange(layouts),
                  }}
                />
              </BookmarkProvider>
            </GoogleOAuthProvider>
            ;
          </Switch>
          <Toaster
            position="top-center"
            toastOptions={{
              style: {
                fontSize: "1rem",
                background: darkMode ? "black" : "white",
                color: darkMode ? "white" : "black",
              },
            }}
          />
        </main>
      </Suspense>
    );
  } else {
    return <></>;
  }
}

export default App;
