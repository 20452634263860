import * as actionTypes from "./actionType";
import {
  axiosInstance,
  feedAxiosInstance,
  remove_auth_headers,
  updateAxiosInstance,
} from "../utility";
import { saveChangedDarkMode } from "src/utils/common";

import { Auth } from "aws-amplify";
import plausible from "src/plausible";
import { check_if_token_is_expired, fetch_user_details } from "../userStore";

export const editArticleListConfig = (payload: any) => {
  return {
    type: actionTypes.EDIT_ARTICLE_LIST_CONFIG,
    payload: payload,
  };
};

export const userEditStart = () => {
  return {
    type: actionTypes.USER_EDIT_START,
  };
};

export const userEditSuccess = (userDetail: any) => {
  return {
    type: actionTypes.USER_EDIT_SUCCESS,
    userDetail: userDetail,
  };
};

export const userEditFail = (error: any) => {
  return {
    type: actionTypes.USER_EDIT_FAIL,
    error: error,
  };
};

export const userDeleteStart = () => {
  return {
    type: actionTypes.USER_DELETE_START,
  };
};

export const userDeleteSuccess = (userDetail: any) => {
  return {
    type: actionTypes.USER_DELETE_SUCCESS,
    userDetail: userDetail,
  };
};
export const setUserDetails = (userDetail: any) => {
  return {
    type: actionTypes.SET_USER_DETAILS,
    payload: userDetail,
  };
};

export const userDeleteFail = (error: any) => {
  return {
    type: actionTypes.USER_DELETE_FAIL,
    error: error,
  };
};

export const userDetailStart = () => {
  return {
    type: actionTypes.USER_DETAIL_START,
  };
};

export const userDetailSuccess = (userDetail: any) => {
  return {
    type: actionTypes.USER_DETAIL_SUCCESS,
    userDetail: userDetail,
  };
};

export const userDetailFail = (error: any) => {
  return {
    type: actionTypes.USER_DETAIL_FAIL,
    error: error,
  };
};

export const logoutResetSuccess = () => {
  return {
    type: actionTypes.USER_LOG_OUT,
  };
};

export const toggleDarkMode = (
  val: boolean,
  user: any,
  customColumnLayoutConfigs: any
) => {
  saveChangedDarkMode(val, user, customColumnLayoutConfigs);
  return {
    type: actionTypes.USER_TOGGLE_DARK_MODE,
    mode: val,
  };
};

export const setSuiteActiveWebColumn = (column: any) => {
  return {
    type: actionTypes.SET_SUITE_ACTIVE_WEB_COLUMN,
    payload: column,
  };
};

export const setSidebarThemeColor = (color: any) => {
  return {
    type: actionTypes.SET_SIDEBAR_THEME_COLOR,
    payload: color,
  };
};

export const logoutReset = () => {
  remove_auth_headers();
  return (dispatch: any) => {
    Auth.signOut().then((temp: any) => {
      updateAxiosInstance("", "", "");
      dispatch(logoutResetSuccess());

      plausible.trackEvent("Logout");
    });
  };
};

export const edituser = (
  user: any,
  onSuccess: any = null,
  onError: any = null
) => {
  return (dispatch: any) => {
    axiosInstance

      .post(`/auth/admin/editUser/`, { data: user })
      .then((res) => {
        dispatch(userEditSuccess(user));
        dispatch(fetch_user_details());
        if (typeof onSuccess == "function") {
          onSuccess(res);
        }
      })
      .catch((err) => {
        dispatch(check_if_token_is_expired(err.response));
        dispatch(userEditFail(err));
        if (typeof onError == "function") {
          onError(err);
        }
      });
  };
};

export const deleteUser = (onSuccess: any = null, onError: any = null) => {
  return (dispatch: any) => {
    axiosInstance

      .post(`/auth/delete/`)
      .then((res) => {
        const { status } = res;
        if (status === 200) {
          dispatch(logoutReset());
        }
        if (typeof onSuccess == "function") {
          onSuccess(res);
        }
      })
      .catch((err) => {
        dispatch(check_if_token_is_expired(err.response));
        dispatch(userDetailFail(err));
        if (typeof onError == "function") {
          onError(err);
        }
      });
  };
};

export const updateDataOnVersion = () => {
  return {
    type: actionTypes.VERSION_CHANGE,
  };
};

export const postSuiteConfig = async (suiteConfig: any) => {
  const darkMode = suiteConfig?.darkMode;
  const email = suiteConfig?.email;
  const layout = suiteConfig?.layout?.filter((item: any) => item?.i !== "");
  const data = {
    suiteConfig: {
      darkMode,
      email,
      layout,
    },
  };
  return axiosInstance
    .post("/auth/setSuiteConfig", data, { timeout: 100000 })
    .then((res: any) => {
      const data = res?.data?.updatedAt;
      localStorage.setItem("suiteConfigUpdatedAt", JSON.stringify(data));
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        console.log("SUITE_CONFIG_CONFORMATION_MODAL");
      }
    });
};
export const postCustomFeedConfig = async (newLayout: any) => {
  const data = newLayout;
  return feedAxiosInstance
    .post("customfeed", data, { timeout: 100000 })
    .then((res: any) => {
      const data = res?.data?.bookmark.updatedAt;
      console.log(res.data);
      // dispatch(
      //   updateCustomFeedData([res.data.bookmark, ...customeFeeds] as any)
      // );
      localStorage.setItem("suiteConfigUpdatedAt", JSON.stringify(data));
    })
    .catch((err) => {
      if (err?.response?.status === 401) {
        console.log("SUITE_CONFIG_CONFORMATION_MODAL");
      }
    });
};

export const getSuiteConfig = async () => {
  return axiosInstance.get("/auth/getSuiteConfig");
};
