export const defaultFilter = {
  keyword: "",
  boolean: "AND",
};

export const firstDefaultFilter = {
  keyword: "",
  boolean: "ONLY",
};

export const tierPrivilege: Record<string, number> = {
  low: 1,
  mid: 3,
  high: 10,
};

export const hideFilterOptionsComponents = [
  "tradingview",
  "investing-economic-calender",
  "telegram",
];

export const filterOptions = ["AND", "OR", "NOT"];
export const firstFilterOptions = ["ONLY", "NOT"];
