import React from "react";
// import { useSelector } from "react-redux";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import { useState } from "react";

import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import { GlobalProps, LayoutProps, SocketNames } from "src/interface/PiqState";
import ColumnLoading from "src/development/Component/Loading";
import useFeeds from "src/Hooks/useFeeds";
// import { updateLocalStorageSuiteLayout } from "src/utils/common";

export const TelegramComponent: React.FC<{
  socket: SocketNames;
  props: GlobalProps;
  layoutProps: LayoutProps;
}> = ({ socket, props, layoutProps }) => {

  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const { getColumnDetail } = useFeeds(props)

  const column = getColumnDetail(socket)

  const refresh_function = () => {
    setFetching(true);
    const time = setTimeout(() => {
      setFetching(false);
    }, 1000);
    return () => clearTimeout(time);
  };


  return (
    <>
      <ColumnHeader
        socket={socket}
        isFetching={isFetching}
        fetchFunction={() => refresh_function()}
        title={column?.title}
        handleEditPanel={() => setShowEditPanel(true)}
      />
      <div
        className={`view-chart-div `}
        style={{ height: showEditPanel ? "100%" : "calc(100% - 24px)" }}
      >
        {showEditPanel && socket ? (
          <ColumnHeaderOption
            setShowEditPanel={setShowEditPanel}
            socket={socket}
            props={props}
            layoutProps={layoutProps}
          />
        ) : (
          <>
            {!isFetching ? (
              <>
                <iframe
                  src={"https://523db25f.piq3rdparty.com/"}
                  title="Telegram"
                  className="view-chart"
                />
              </>
            ) : (
              <ColumnLoading />
            )}
          </>
        )}
      </div>
    </>
  );
};
