import React from "react";
import { PartnerFeedComponent } from "src/development/Container/Web/Components/Feeds/PartnerFeeds";
import { TwitterListComponent } from "src/development/Container/Web/Components/Feeds/TwitterListColumn";
import { TradingViewComponent } from "src/development/Container/Web/Components/Feeds/TradingView/TradingView";
import { VideoPlayer } from "src/development/Container/Web/Components/Feeds/VideoPlayer";
import { GlobalProps, LayoutProps, SocketNames } from "src/interface/PiqState";
import { InvestingEconomicCalenderComponent } from "src/development/Container/Web/Components/Feeds/InvestingEconomicCalender";
import { CompleteIntelComponent } from "src/development/Container/Web/Components/Feeds/CompleteIntel";
import { TelegramComponent } from "src/development/Container/Web/Components/Feeds/Telegram";
import { IndividualFeedProvider } from "src/context/IndividualFeedProvider";
import Podcast from "src/development/Container/Web/Components/Feeds/Podcast";
import { CustomFeedComponent } from "src/development/Container/Web/Components/Feeds/CustomFeedComponent";

const getChartComponet = (
  socket: SocketNames,
  props: GlobalProps,
  layoutProps: LayoutProps
) => {
  switch (socket) {
    case "tradingview":
      return (
        <TradingViewComponent
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
    case "investing-economic-calender":
      return (
        <InvestingEconomicCalenderComponent
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
    case "complete-intel":
      return (
        <CompleteIntelComponent
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
  }
};

const getAppComponet = (
  socket: SocketNames,
  props: GlobalProps,
  layoutProps: LayoutProps
) => {
  switch (socket) {
    case "telegram":
      return (
        <TelegramComponent
          socket={socket}
          props={props}
          layoutProps={layoutProps}
        />
      );
  }
};

export const getWebFeedComponent = (
  colString: SocketNames,
  props: GlobalProps,
  layoutProps: LayoutProps,
  c: any
) => {
  if (
    c?.componentType === "NEWS_FEED_COMPONENT" ||
    c?.componentType === "PREMIUM_FEED_COMPONENT"
  ) {
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id}>
          <PartnerFeedComponent
            socket={c.slug}
            props={props}
            layoutProps={layoutProps}
          />
        </IndividualFeedProvider>
      ),
    };
  }

  if (c?.componentType === "TWITTER_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id}>
          <TwitterListComponent
            socket={c.slug}
            props={props}
            layoutProps={layoutProps}
          />
        </IndividualFeedProvider>
      ),
    };
  }
  if (c?.componentType === "CUSTOM_FEED_COMPONENT") {
    console.log(c)
    return {
      socket: c.slug,
      component: (
        <IndividualFeedProvider feedId={c?._id}>
          <CustomFeedComponent
            socket={c.slug}
            props={props}
            layoutProps={layoutProps}
            feedData={c}
          />
        </IndividualFeedProvider>
      ),
    };
  }

  if (c?.componentType === "SOCIAL_APP_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: getAppComponet(c.slug, props, layoutProps),
    };
  }

  if (c?.componentType === "CHART_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: getChartComponet(c.slug, props, layoutProps),
    };
  }
  if (c?.componentType === "APP_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: getAppComponet(c.slug, props, layoutProps),
    };
  }
  if (c?.componentType === "PIQ_PODCAST_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <Podcast socket={c.slug} props={props} layoutProps={layoutProps} />
      ),
    };
  }
  if (c?.componentType === "VIDEO_FEED_COMPONENT") {
    return {
      socket: c.slug,
      component: (
        <VideoPlayer socket={c.slug} props={props} layoutProps={layoutProps} />
      ),
    };
  } else {
    return {
      socket: colString,
      component: (
        <VideoPlayer
          socket={colString}
          props={props}
          layoutProps={layoutProps}
        />
      ),
    };
  }
};
