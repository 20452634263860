export const enum trackEvents {
  "SIGNUP_WITH_EMAIL_BTN_CLICK" = "S_U_WITH_EMAIL_BTN",
  "SIGNUP_BTN" = "S_U_BTN",
  "SIGNUP_TC" = "S_U_TC",
  "SIGNUP_EMAIL_UPDATES" = "S_U_EU",
  "INPUT_FIELD_COMPLETE" = "INPUT_FIELD_COMPLETE",
  "AUTH_DRAWER" = "AUTH_DRAWER",
  "DRAWER_CLOSED" = "DRAWER_CLOSED",
  "REGISTER_DRAWER_OPEN" = "R_D_O",
  "RESET_PASSWORD_DRAWER_OPEN" = "R_P_D_O",
  "SIGN_IN_DRAWER_OPEN" = "S_I_D_O",
  "REGISTER_DRAWER_CLOSED" = "R_D_C",
  "RESET_PASSWORD_DRAWER_CLOSED" = "R_P_D_C",
  "SIGN_IN_DRAWER_CLOSED" = "S_I_D_C",
}
