import { Button } from "antd";
import React from "react";
import closeIcon from "src/style/images/close_icon.svg";
import {
    CloseCircleOutlined,
} from "@ant-design/icons";
import { GlobalProps, LayoutProps } from "src/interface/PiqState";
import useColumnHeader from "src/Hooks/useColumnHeader";


const PodcastHeaderOption: React.FC<{
    setShowEditPanel: (params: any) => any;
    props: GlobalProps;
    layoutProps: LayoutProps;
    slug: string;
}> = ({ setShowEditPanel, props, slug, layoutProps }) => {
    const { removeColumn } = useColumnHeader(layoutProps);

    return (
        <div className="v2-column-header-option"

            style={{
                height: "100%"
            }}
        // style={{
        //   height: socket === "harkster" || socket === "forexLive" || socket === "telegram" ? "96%" : socket === "reuters" ? "94%" : socket === "tradingview" ? "91%" : "100%",
        //   width: socket === "harkster" || socket === "forexLive" ? "96%" : socket === "reuters" ? "98.6%" : "99%",

        // }}
        >
            <div style={{ textAlign: "right" }}>
                <Button
                    type="text"
                    shape="circle"
                    size="small"
                    className="v2-column-header-option-close-btn"
                    icon={
                        <img src={closeIcon} alt="" className="v2-column-header-option-close-btn-icon" />
                    }
                    onClick={() => setShowEditPanel(false)}
                />
            </div>

            <div
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => removeColumn(slug, layoutProps)}
            >
                <CloseCircleOutlined /> Delete Feed
            </div>
        </div>
    );
};

export default PodcastHeaderOption;
