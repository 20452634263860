import React from "react";
import useInFeedAds from "src/Hooks/useInFeedAds";
import config from "src/config";
import { GlobalProps } from "src/interface/PiqState";

export const InFeedAds: React.FC<{ item: any, props: GlobalProps }> = ({ item, props }) => {
    const { handleAdsClick } = useInFeedAds(props)
    return (
        <>
            {
                item?.list_item_type === 'IN_FEED_ADS' && <div
                    className={`v2-suite-column-card-item`}
                    style={{ cursor: 'pointer', padding: "0px !important" }} onClick={() => handleAdsClick(item)}>

                    <img src={config?.FEED_URL + item?.image_url}
                        alt="https://piqsuite.com"
                        style={{ width: "-webkit-fill-available" }}
                    />
                </div>
            }
        </>
    )
}