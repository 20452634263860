import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { GlobalProps } from "src/interface/PiqState";

const CustomFeedNameEdit: React.FC<{
  socket: string;
  props: GlobalProps;
}> = ({ socket, props }) => {
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));

  const customeFeeds = useSelector((state: any) => {
    return state?.piqcolumns?.customFeeds
  })
  useEffect(() => {
    const feedToEdit = customeFeeds.find((feed: any) => feed.slug === socket)
    console.log(feedToEdit)
  }, [customeFeeds])
  return (
    <div className={darkMode ? "dark-feed-name-edit-main-container" : "feed-name-edit-main-container"}>
      <input value={"Custom Feed"}></input>
    </div>
  );
};

export default CustomFeedNameEdit;
