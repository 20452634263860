/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { executeScroll, handleColumnScroll } from "src/utils/common";
import { NewsFeedProps } from "src/interface/PartnerFeed";
import { socket as socketInstance } from "src/Socket";
import ScrollToTop from "../scrollToTop";
import { fetchPartnerFeedData, onLoadSocketData } from "src/store/PIQ/action";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import NewsFeedList from "src/development/Component/PartnerFeed";
import { useAuthContext } from "src/context/AuthProvider";
import useSound from "use-sound";
import dingSound from "src/sound/ding.mp3";
import { checkIfDivHasHighlight, filterColFeed } from "src/utils/utilFunction";
import useColConfig from "src/Hooks/useColConfig";
import useFeeds from "src/Hooks/useFeeds";
import { LoadMoreButton } from "src/development/Component/LoadMoreButton";
import useInFeedAds from "src/Hooks/useInFeedAds";
import FeedSearch from "./FeedSearch";
import { IndividualFeedContext } from "src/context/IndividualFeedProvider";
import { message } from "antd";
import { NewsquawkMessageComponent } from "src/development/Component/Newsquawk";
const PAGE_LIMIT = 50;
export const PartnerFeedComponent: React.FC<NewsFeedProps> = ({
  socket,
  props,
  layoutProps,
}) => {
  const dispatch = useDispatch();
  const dataColRef = useRef<HTMLDivElement>(null);
  const [displayScrollTop, setScrollTop] = useState<boolean>(false);
  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [isSearchLoading, setIsSearchLoading] = useState<boolean>(false);
  const [page, setPage] = useState<number>(1);
  const context: any = useAuthContext();
  const { colConfig } = useColConfig({ socket, showEditPanel });
  // const { colFilterConfig, openByDefault, setOpenByDefault } = useColFilterConfig({ feedId: feedId });
  const [data, setData] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const [isSocketLoading, setIsSocketLoading] = useState<boolean>(false);
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const { getColumnDetail } = useFeeds(props);
  const column = getColumnDetail(socket);
  const { pushAdsToColumn, getColumnAds } = useInFeedAds(props);
  const [adsCoordinates, setAdsCoordinates] = useState<any>([]);
  const [searchedText, setSearchedText] = useState<any>("");
  const column_ads = getColumnAds(socket);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [play, { stop }] = useSound(dingSound, { volume: 1 });


  const {
    loading,
    colFilterConfig,
    setColFilterConfig,
    openByDefault,
    setOpenByDefault,
    filterDisabled,
    setFilterDisabled,
  } = useContext(IndividualFeedContext);


  const filteredLists = useMemo(
    () =>
      colFilterConfig?.is_active && !filterDisabled
        ? filterColFeed(data, colFilterConfig?.filters)
        : data,
    [data, colFilterConfig, filterDisabled]
  );


  useEffect(() => {
    (async () => {
      setIsSearchLoading(true);
      fetchPartnerFeedData(
        setReload,
        PAGE_LIMIT,
        socket,
        setData,
        setTotalPage,
        false,
        adsCoordinates,
        column_ads,
        searchedText,
      );
      setIsSearchLoading(false);

      // audio.pause()
    })();
    return () => {
      setScrollTop(false);
      setShowEditPanel(false);
      setShowSearch(false);
      setIsFetching(false);
      setReload(false);
      setData([]);
      setPage(1);
      setTotalPage(1);
      stop();
    };
  }, [dispatch, socket]);

  function findUniqueItems(data1: any[], data2: any[]) {
    const data2Keys = data2.map((item) => item["title"]);
    return data1.filter((item) => !data2Keys.includes(item["title"]));
  }

  useEffect(() => {
    const onData = async (newData: any) => {
      newData = {
        ...newData,
        data: findUniqueItems(newData?.data, data),
      };

      for (let i = 0; i < newData?.data?.length; i++) {
        let data_with_ads: any;
        const tempData = {
          ...newData,
          data: [newData?.data[i]],
        };

        data_with_ads = pushAdsToColumn(
          tempData,
          socket,
          data,
          adsCoordinates,
          setAdsCoordinates
        );
        if (isSocketLoading) return;
        setIsSocketLoading(true);
        await onLoadSocketData(
          data_with_ads,
          dataColRef,
          displayScrollTop,
          colConfig,
          checkIfDivHasHighlight,
          context,
          play,
          data,
          PAGE_LIMIT,
          socket,
          setData,
          false,
          setIsSocketLoading
        );
      }
    };

    socketInstance.on(socket, onData);

    return () => {
      socketInstance.off(socket, onData);
    };
  });



  const handleSearch = (e: any) => {
    setFilterDisabled(true);
    const search_text = e.target.value;
    setSearchedText(search_text);
    setIsSearchLoading(true);
    fetchPartnerFeedData(
      setReload,
      PAGE_LIMIT,
      socket,
      setData,
      setTotalPage,
      false,
      adsCoordinates,
      column_ads,
      search_text,
    );
    setIsSearchLoading(false);
  };

  useEffect(() => {
    if (!isUserLoggedIn) {
      setShowSearch(false);
      setSearchedText("");
      fetchPartnerFeedData(
        setReload,
        PAGE_LIMIT,
        socket,
        setData,
        setTotalPage,
        false,
        adsCoordinates,
        column_ads,
        "",
      );
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    setScrollTop(false);
  }, [filteredLists, filterDisabled]);

  return (
    <>
      <ColumnHeader
        navImg={column?.icon}
        title={column?.title || ""}
        isFetching={reload}
        fetchFunction={() => {
          setIsSearchLoading(true);
          fetchPartnerFeedData(
            setReload,
            PAGE_LIMIT,
            socket,
            setData,
            setTotalPage,
            false,
            adsCoordinates,
            column_ads,
            searchedText
          );
          setIsSearchLoading(false);
          // stop();
        }}
        socket={socket}
        handleEditPanel={() => {
          setOpenByDefault("");
          setShowEditPanel(true);
          setShowSearch(false);
        }}
        handleSearch={() => {
          if (!isUserLoggedIn) {
            props.setActiveDrawer("signup-drawer");
            message.warning("You need to login to access search");
          } else {
            // showEditPanel ? setShowSearch(false) : setShowSearch(!showSearch);
            if (showEditPanel) {
              setShowSearch(false);
            } else {
              setShowSearch(!showSearch);
              setFilterDisabled((prev) => !prev);
            }
          }
        }}
        toggleFilter={() => {
          if (!showEditPanel) {
            setShowEditPanel(true);
            setOpenByDefault("5");
            setShowSearch(false);
          } else {
            setShowEditPanel(false);
            setOpenByDefault("");
          }
        }}
        searchedText={searchedText}
      />
      <FeedSearch
        showSearch={showSearch}
        handleSearch={handleSearch}
        searchedText={searchedText}
      />
      {showEditPanel && socket ? (
        <ColumnHeaderOption
          props={props as any}
          setShowEditPanel={setShowEditPanel}
          socket={socket}
          layoutProps={layoutProps as any}
          defaultActivePanel={openByDefault}
        />
      ) : (
        <div
          ref={dataColRef}
          onScroll={() => {
            if (dataColRef.current) {
              const { scrollTop } = dataColRef.current;
              if (dataColRef.current && scrollTop >= 90) {
                setScrollTop(true);
              } else {
                setScrollTop(false);
              }
            }
          }}
          className="v2-suite-column"
        >
          {!reload && (
            <div className="list-container-article-viewer">
              <NewsquawkMessageComponent socket={socket} props={props} />
              <NewsFeedList
                list={filteredLists}
                socket={socket}
                colConfig={colConfig}
                twitterAccount={column?.twitterAccount}
                props={props as any}
                isSearchLoading={isSearchLoading}
                searchedText={searchedText}
              />
            </div>
          )}

          <LoadMoreButton
            totalPage={totalPage}
            page={page}
            data={filteredLists}
            handleColumnScroll={handleColumnScroll}
            isFetching={isFetching}
            setIsFetching={setIsFetching}
            socket={socket}
            dataColRef={dataColRef}
            setScrollTop={setScrollTop}
            setPage={setPage}
            pageLimit={PAGE_LIMIT}
            setData={setData}
            api={`/${socket}?search=${searchedText}`}
            isFilterOn={colFilterConfig?.is_active && colFilterConfig?.filters?.length !== 0}
          />

          <ScrollToTop
            displayScrollTop={displayScrollTop}
            executeScrollToTop={() => {
              executeScroll(dataColRef);
              setIsSearchLoading(true);
              fetchPartnerFeedData(
                setReload,
                PAGE_LIMIT,
                socket,
                setData,
                setTotalPage,
                true,
                adsCoordinates,
                column_ads,
                searchedText
              );
              setIsSearchLoading(false);
              setScrollTop(false);
            }}
          />
        </div>
      )}
    </>
  );
};
