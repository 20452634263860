import socketIOClient from "socket.io-client";
import config from "../config";

export const socket: any = socketIOClient(`${config.FEED_URL}`, {
  // transports: ["websocket",'polling'],

  auth: {
    username: "",
    email: "",
  },
  reconnection: true,
  autoConnect: false,
  reconnectionAttempts: 1000, // number of reconnection attempts before giving up [Infinity]
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000, // maximum amount of time to wait between reconnection attempts. Each attempt increases the reconnection delay by 2x along with a randomization factor
  randomizationFactor: 0.5,
});

export const runSocketConnect = async (email: any) => {
  if (socket !== undefined) {
    socket.auth.email = email;
    socket.connect();
  }
};

export const logoutSocketEmit = (email: any) => {
  if (socket !== undefined) {
    socket.emit("logout", {
      email: email,
    });
  }
};

export const loginSocketEmit = (email: any) => {
  if (socket !== undefined) {
    socket.emit("login", {
      email: email,
    });
  }
};
