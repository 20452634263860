import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { socket as socketInstance } from "src/Socket";
import PriaCard from "src/development/Component/TwitterCard";
import ColumnHeaderOption from "src/development/Component/ColumnHeader/Column-Header-Option";
import { executeScroll, handleColumnScroll } from "src/utils/common";
import ScrollToTop from "../scrollToTop";
import { NewsFeedProps } from "src/interface/PartnerFeed";
import { fetchTwitterFeedData, onLoadSocketData } from "src/store/PIQ/action";
import ColumnHeader from "src/development/Component/ColumnHeader/Column-Header";
import { useAuthContext } from "src/context/AuthProvider";
import {
  checkIfDivHasHighlight,
  filterColFeed,
  getDivHighlightColor,
} from "src/utils/utilFunction";
import useSound from "use-sound";
import dingSound from "src/sound/ding.mp3";
import useColConfig from "src/Hooks/useColConfig";
import { SingleKeywordColConfig } from "src/interface/keyword";
import useFeeds from "src/Hooks/useFeeds";
import { LoadMoreButton } from "src/development/Component/LoadMoreButton";
import useInFeedAds from "src/Hooks/useInFeedAds";
import { InFeedAds } from "src/development/Component/InFeedAds";
import FeedSearch from "./FeedSearch";
import { IndividualFeedContext } from "src/context/IndividualFeedProvider";
import { message } from "antd";
import FeedSearchNotFound from "src/development/Component/Feeds/FeedSearchNotFound";
import ColumnLoading from "src/development/Component/Loading";
const PAGE_LIMIT = 50;

export const TwitterListComponent: React.FC<NewsFeedProps> = ({
  socket,
  props,
  layoutProps,
}) => {
  const dispatch = useDispatch();
  const [showEditPanel, setShowEditPanel] = useState<boolean>(false);
  const [displayScrollTop, setScrollTop] = useState<boolean>(false);
  const [isSocketLoading, setIsSocketLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const context: any = useAuthContext();
  const dataColRef: any = useRef<HTMLDivElement>(null);
  const [reload, setReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<any[]>([]);
  const [totalPage, setTotalPage] = useState<number>(1);
  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));
  const [page, setPage] = useState<number>(1);
  const { colConfig } = useColConfig({ socket, showEditPanel });
  const { getColumnDetail } = useFeeds(props);
  const { pushAdsToColumn, getColumnAds } = useInFeedAds(props);
  const column = getColumnDetail(socket);
  const [adsCoordinates, setAdsCoordinates] = useState<any>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [play, { stop }] = useSound(dingSound, { volume: 1 });
  const { isSignedIn: isUserLoggedIn } = useAuthContext();
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [searchedText, setSearchedText] = useState<any>("");

  const column_ads = getColumnAds(socket);

  const {
    loading,
    colFilterConfig,
    setColFilterConfig,
    openByDefault,
    setOpenByDefault,
    filterDisabled,
    setFilterDisabled,
  } = useContext(IndividualFeedContext);

  const filteredLists = useMemo(
    () =>
      colFilterConfig?.is_active && !filterDisabled
        ? filterColFeed(data, colFilterConfig?.filters)
        : data,
    [data, colFilterConfig, filterDisabled, searchedText]
  );

  useEffect(() => {
    setScrollTop(false)
  }, [filteredLists, filterDisabled])


  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setReload(true);
      fetchTwitterFeedData(
        setReload,
        PAGE_LIMIT,
        `tweet/${socket}`,
        setData,
        setTotalPage,
        false,
        adsCoordinates,
        column_ads,
        searchedText
      );
      setIsLoading(false);
    })();

    return () => {
      setScrollTop(false);
      setShowEditPanel(false);
      setIsFetching(false);
      setReload(false);
      setData([]);
      setPage(1);
      setTotalPage(1);
      stop();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, socket]);

  function findUniqueItems(data1: any[], data2: any[]) {
    const data2Keys = data2.map((item) => item["_id"]);
    return data1.filter((item) => !data2Keys.includes(item["_id"]));
  }

  useEffect(() => {
    const onData = async (newData: any) => {
      if (!newData?.data?.length) return;
      newData = {
        ...newData,
        data: findUniqueItems(newData?.data, data),
      };

      for (let i = 0; i < newData?.data?.length; i++) {
        let data_with_ads: any;
        const tempData = {
          ...newData,
          data: [newData?.data[i]],
        };
        data_with_ads = pushAdsToColumn(
          tempData,
          socket,
          data,
          adsCoordinates,
          setAdsCoordinates
        );
        setIsSocketLoading(true);
        if (isSocketLoading) return;
        await onLoadSocketData(
          data_with_ads,
          dataColRef,
          displayScrollTop,
          colConfig,
          checkIfDivHasHighlight,
          context,
          play,
          data,
          PAGE_LIMIT,
          socket,
          setData,
          true,
          setIsSocketLoading
        );
      }
    };
    socketInstance.on(socket, onData);
    return () => {
      socketInstance.off(socket, onData);
    };
  });
  const divHasHighligts = (
    title: string,
    colConfig: SingleKeywordColConfig
  ) => {
    let isTrue = false;

    const borderColor = getDivHighlightColor(title, colConfig);
    if (
      isUserLoggedIn &&
      checkIfDivHasHighlight(title, colConfig) &&
      borderColor
    ) {
      isTrue = true;
    }
    if (isTrue) {
      return borderColor;
    } else {
      return false;
    }
  };

  const handleSearch = (e: any) => {
    setFilterDisabled(true);
    const search_text = e.target.value;
    setSearchedText(search_text);
    setIsLoading(true);
    setReload(true);
    fetchTwitterFeedData(
      setReload,
      PAGE_LIMIT,
      `tweet/${socket}`,
      setData,
      setTotalPage,
      false,
      adsCoordinates,
      column_ads,
      search_text
    );
    setIsLoading(false);
  };
  // let articlesChunks = splitArrayIntoChunks(data, socket);

  useEffect(() => {
    if (!isUserLoggedIn) {
      setShowSearch(false);
      setSearchedText("");
      fetchTwitterFeedData(
        setReload,
        PAGE_LIMIT,
        `tweet/${socket}`,
        setData,
        setTotalPage,
        false,
        adsCoordinates,
        column_ads,
        ""
      );
    }
  }, [isUserLoggedIn]);
  return (
    <>
      <ColumnHeader
        navImg={column?.icon}
        title={column?.title || ""}
        isFetching={reload}
        fetchFunction={() => {
          setIsLoading(true);

          fetchTwitterFeedData(
            setReload,
            PAGE_LIMIT,
            socket,
            setData,
            setTotalPage,
            false,
            adsCoordinates,
            column_ads,
            searchedText
          );
          setIsLoading(false);
        }}
        socket={socket}
        handleEditPanel={() => {
          setShowEditPanel(true);
          setShowSearch(false);
        }}
        handleSearch={() => {
          if (!isUserLoggedIn) {
            props.setActiveDrawer("signup-drawer");
            message.warning("You need to login to access search");
          } else {
            // showEditPanel ? setShowSearch(false) : setShowSearch(!showSearch);
            if (showEditPanel) {
              setShowSearch(false);
            } else {
              setShowSearch(!showSearch);
              setFilterDisabled((prev) => !prev);
            }
          }
        }}
        toggleFilter={() => {
          if (!showEditPanel) {
            setShowEditPanel(true);
            setOpenByDefault("5");
            setShowSearch(false);
          } else {
            setOpenByDefault("");
            setShowEditPanel(false);
          }
        }}
        searchedText={searchedText}
      />
      {showSearch && (
        <FeedSearch handleSearch={handleSearch} searchedText={searchedText} />
      )}

      {showEditPanel && socket ? (
        <ColumnHeaderOption
          props={props as any}
          setShowEditPanel={setShowEditPanel}
          socket={socket}
          layoutProps={layoutProps}
          defaultActivePanel={openByDefault}
        />
      ) : (
        <div
          ref={dataColRef}
          onScroll={() => {
            if (dataColRef.current) {
              const { scrollTop } = dataColRef.current;
              if (dataColRef.current && scrollTop >= 90) {
                setScrollTop(true);
              } else {
                setScrollTop(false);
              }
            }
          }}
          className="v2-suite-column"
        >
          {filteredLists.length ? (
            !reload ? (
              filteredLists?.map((tweetDetail: any, index: number) => {
                return (
                  <div key={index}>
                    <InFeedAds item={tweetDetail} props={props} />
                    <div
                      hidden={tweetDetail?.list_item_type === "IN_FEED_ADS"}
                      style={{
                        padding: "5px 10px 8px 15px",
                        border:
                          isUserLoggedIn &&
                            colConfig?.highlightEntireCard &&
                            divHasHighligts(tweetDetail?.full_text, colConfig)
                            ? `${`2px solid ${divHasHighligts(
                              tweetDetail?.full_text,
                              colConfig
                            )}`}`
                            : "",
                        paddingBottom: 3,
                        backgroundColor: darkMode
                          ? isUserLoggedIn &&
                            colConfig?.highlightEntireCard &&
                            checkIfDivHasHighlight(
                              tweetDetail?.full_text,
                              colConfig
                            )
                            ? "#515A67"
                            : "#384251"
                          : "white",

                        color:
                          isUserLoggedIn &&
                            checkIfDivHasHighlight(
                              tweetDetail?.full_text,
                              colConfig
                            )
                            ? "white"
                            : "none",
                      }}
                      className={`v2-suite-column-card-item`}
                      key={index}
                    >
                      <PriaCard
                        key={index}
                        colConfig={colConfig}
                        rumble={tweetDetail}
                        handleImage={() => { }}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <></>
            )
          ) : reload ? (
            <ColumnLoading />
          ) : (
            <FeedSearchNotFound searchedText={searchedText} />
          )}

          {filteredLists.length ? (
            <LoadMoreButton
              totalPage={totalPage}
              page={page}
              data={filteredLists}
              handleColumnScroll={handleColumnScroll}
              isFetching={isFetching}
              setIsFetching={setIsFetching}
              socket={socket}
              dataColRef={dataColRef}
              setScrollTop={setScrollTop}
              setPage={setPage}
              pageLimit={PAGE_LIMIT}
              setData={setData}
              api={`/tweet/${socket}`}
              isFilterOn={colFilterConfig?.is_active}
            />
          ) : (
            <></>
          )}

          <ScrollToTop
            displayScrollTop={displayScrollTop}
            executeScrollToTop={() => {
              executeScroll(dataColRef);
              setIsLoading(true);

              fetchTwitterFeedData(
                setReload,
                PAGE_LIMIT,
                `tweet/${socket}`,
                setData,
                setTotalPage,
                true,
                adsCoordinates,
                column_ads,
                searchedText
              );
              setScrollTop(false);
              setIsLoading(false);
            }}
          />
        </div>
      )}
    </>
  );
};
