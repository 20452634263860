import React, { useEffect, useState } from "react";
import { GlobalProps } from "src/interface/PiqState";
import { Button, Checkbox } from "antd";
import { useSelector } from "react-redux";

const CustomFeedAdd: React.FC<{
  socket: string;
  props: GlobalProps;
  handleAddCustomFeed: any
}> = ({ socket, props, handleAddCustomFeed }) => {

  const [slugs, setSlugs] = useState([])
  const [title, setTitle] = useState("")
  const onChange = (checkedValues: any) => {
    setSlugs(checkedValues);
  };

  const { darkMode } = useSelector((state: any) => ({
    darkMode: state?.user?.darkMode,
  }));
  const Columns = useSelector((state: any) => {
    return state?.piqcolumns?.columns;
  });

  const options = Columns?.map((data: any) => ({ label: data?.title, value: data?.slug }))

  const customeFeeds = useSelector((state: any) => {
    return state?.piqcolumns?.customFeeds
  })

  useEffect(() => {
    const feedToEdit = customeFeeds.find((feed: any) => feed.slug === socket)
    if (feedToEdit) {
      setSlugs(feedToEdit?.slugs)
      setTitle(feedToEdit?.title)
    }
  }, [customeFeeds])

  console.log(slugs)

  return (
    <div className={darkMode ? "dark-custom-add-feed-main-container" : "custom-add-feed-main-container"}>
      <div>{slugs.length} Selected</div>
      <div className="feed-lists">
        <Checkbox.Group
          options={options}
          defaultValue={slugs}
          onChange={onChange}
        />
        {/* <br />
        <br />
        <Checkbox.Group
          options={optionsWithDisabled}
          disabled
          defaultValue={["Apple"]}
          onChange={onChange}
        /> */}
      </div>
      <div>
        <Button type="primary" onClick={(e) => handleAddCustomFeed(e, socket, slugs, title)}>Add</Button>
      </div>
    </div>
  );
};

export default CustomFeedAdd;
